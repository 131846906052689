import { createContext, useContext, useEffect, useMemo, useState } from "react";

const ModalContext = createContext();

const ModalProvider = ({ children }) => {
  const [modal, setModal_] = useState(false);
  const [message, setMessage_] = useState(false);
  
  const setModal = (open) => {
    setModal_(open);
  };

  const setMessage = (text) => {
    setMessage_(text)
  }

  // Memoized value of the authentication context
  const contextValue = useMemo(
    () => ({
      modal,
      message,
      setModal,
      setMessage

    }),
    [modal, message]
  );

  // Provide the authentication context to the children components
  return (
    <ModalContext.Provider value={contextValue}>{children}</ModalContext.Provider>
  );
};

export const useModal = () => {
  return useContext(ModalContext);
};

export default ModalProvider;