import axios from 'axios';

export const request = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}/`,
  headers: {
        'Access-Control-Allow-Origin': '*',
  }
});
request.interceptors.request.use(
    (config) => {
      const token = localStorage.getItem('token');
      if (token) {
        config.headers.Authorization = `Bearer ${token}`;
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

export const AuthApi = {
    me () {
        return request.get('/users/me')
    },
    changeProfile (data){
        return request.put('/users/profile', data)
    },
    clients(page, search) {
        return request.get('/users', {params: {page, 'search': search}})
    },
    changeManager(user) {
        return request.put('/users/manager', user)
    },
    login (email, password) {
        const formData = new FormData()
        formData.append("username", email)
        formData.append("password", password)
        return request.post(`/auth/token`, formData)
    },
    logout () {
        return 
    },
    registration (data) {
        return request.post('/auth/registration', data)
    },
    activate (user) {
        return request.post('/users/activate', user)
    },
    delete (email) {
        return request.delete(`/users`, {params: {email}})
    },
    managers (){
        return request.get('/users/managers')
    }
}

export const CatalogApi = {
    getList (params){
        return request.get('/catalog/list', {params: params})
    },
    getFilters (){
        return request.get('/catalog/filters')
    },
    get (item_id) {
        return request.get(`/catalog/${item_id}`)
    },
    editInfo(info){
        return request.put('/catalog/info', info)
    },
    export () {
        request.get('/catalog/export',  {
            responseType: 'blob'
        }).then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'export.xlsx');
            document.body.appendChild(link);
            link.click();
        })
        return 
    }
}

export const GallaryApi = {
    create (image) {
        return request.post('/gallery/add', { file: image }, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })
    },
    import (file) {
        return request.post('/gallery/zip', { file: file }, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })
    },
    getList () {
        return request.get('/gallery/list')
    },
    delete (id) {
        return request.delete(`/gallery/${id}`)
    }
}

export const StoreApi = {
    getItems(params=''){
        return request.get(`/store?${params}`)
    },
    getList (params){
        return request.get(`/store/list${params}`)
    },
    getFilters () {
        return request.get('/store/filters')
    },
    get (item_id) {
        return request.get(`/store/${item_id}`)
    },

}

export const ContainerApi = {
    getItems (container_id, params){
        return request.get(`/container${container_id ? '/' + container_id: ''}`, {params: params})
    },
    getList (params) {
        return request.get('/container/all', {params: params})
    },
    get (params, container_id) {
        return request.get(`/container/${container_id}`, {params: params})
    },
    getAll () {
        return request.get(`/container/list`)
    },
    getItem(item_id){
        return request.get(`/container/item/${item_id}`)
    },
    getFilters (){
        return request.get('/container/filters')
    },
    getStatus (){
        return request.get('/container/status')
    },
    edit(container_id, data){
        return request.put(`/container/${container_id}`, data)
    },
    add (file, params) {
        return request.post('/container/add', {file: file}, {
            headers: {
                'Content-Type': 'multipart/form-data'
            },
            params: params
        })
    },
    delete (container_id){
        return request.delete(`/container/${container_id}`)
    }
}

export const PreOrderApi = {
    getCart (client_id){
        return request.get('/preorder/cart', {params: {client_id: client_id}})
    },
    send(){
        return request.post('/preorder/cart/send')
    },
    getOrders(){
        return request.get('/preorder/list')
    },
    add (item_id, quantity, price, container_id) {
        return request.post('/preorder/add', {item_id: item_id, quantity:quantity, price:price, container_id:container_id})
    },
    edit (item_id, quantity){
        return request.put(`/preorder/cart/${item_id}`, {quantity: quantity})
    },
    delete (item_id){
        return request.delete(`/preorder/cart/${item_id}`)
    }
}

export const OrderApi = {
    cancelOrder (order) {
        return request.put('/order/cancel/request', order)
    },
    cancelConfirm (order){
        return request.put('/order/cancel/confirm', order)
    },
    sendChanged(items, order_id){
        return request.post('/order/send_change', items, {params: {order_id: order_id}})
    },
    getCart (client_id) {
        return request.get('/order/cart', {params: {client_id: client_id}})
    },
    getStatus () {
        return request.get('/order/status/list')
    },
    changeStatus (order_id, status_id) {
        return request.post(`/order/${order_id}/changeStatus`, {status_id: status_id})
    },
    get (order_id) {
        return request.get(`/order/${order_id}`)
    },
    getOrders (){
        return request.get('/order/all')
    },
    getItems(order_id){
        return request.get(`/order`, {params: {order_id: order_id}})
    },
    delete (item_id) {
        return request.delete(`/order/cart/${item_id}`)
    },
    add (item_id, quantity, price, storage_id) {
        return request.post(`/order/add_to_cart`, {item_id: item_id, quantity: quantity, price: price, storage_id: storage_id})
    },
    edit (item) {
        return request.put(`/order/cart`, item=item)
    },
    addEdit (item){
        return request.post('/order/add', item=item)
    },
    editItemOrder (item, item_order_id){
        return request.put('/order/item', item=item, {params:{order_item_id:item_order_id}})
    },
    send () {
        return request.post('/order/cart/send')
    }
}

export const ConfigApi = {
    list (){
        return request.get('/settings/list')
    },
    update (config, data) {
        return request.put('settings/' + config + '/update', data)
    },

}
