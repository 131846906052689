import { ConfigApi } from '../../api';
import { makeAutoObservable } from 'mobx';


export class SettingsStore {

    catalog = new Map()
    mail = new Map()

    constructor() {
        makeAutoObservable(this)
    }

    setCatalog = (key, value) => {
        console.log(this.catalog)
        this.catalog.set(key, value)
    }
    
    setMail = (key, value) => {
        this.mail.set(key, value)
    }

    setConfig(data) {
        if (data.length == 0) {
            return
        }
        data.forEach(element => {
            if (element.key === 'mail') {
                this.mail = new Map(Object.entries(element.value));  // Преобразуем объект в Map
            }
            if (element.key === 'catalog') {
                this.catalog = new Map(Object.entries(element.value));  // Преобразуем объект в Map
            }
        });
    }

    fetchGetSettings = async () => {
        try{
            const {data} = await ConfigApi.list()
            this.setConfig(data)
            
        }catch(error){
            console.log(error)
        }
    }  

    fetchUpdateSettings = async (name) => {
        try {
            const config = name === 'catalog' ? Object.fromEntries(this.catalog) : name === 'mail' ? Object.fromEntries(this.mail) : null;
            if (!config) {
                alert('Ошибка обновления настроек');
                return;
            }
            const {data} = await ConfigApi.update(name, config);
            this.setConfig(data);
            alert('Обновлено');
        } catch (error) {
            console.log(error);
        }
    }
    
}
const settingsStore = new SettingsStore()
export default settingsStore

