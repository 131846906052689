import React, { useState, useEffect, useRef } from 'react';
import Filters from '../components/Filters'
import Pagination from '../components/Pagination';
import { NavLink, useLoaderData, Form, useSearchParams } from 'react-router-dom';
import Search from '../components/Search';
import { findFirstMatch, findAllMatches, formatDate, formatRub, useOutsideClick } from '../utils';
import { ContainerApi } from '../api';
import Breadcrumbs from '../components/Breadcrumbs';
import { AdditionalAttributes } from '../components/Detail';
import Alert from '../components/Alert';
import { Table, TableRow, TableCell, Thead } from '../components/table/Table'
import { IntegerFieldTest } from '../components/input/IntegerField';
import { ModalBlock, Modal, ModalBlockElement } from '../components/window/Modal';
import ImageCard from '../components/image/ImageCard';
import MainButton from '../components/button/MainButton';
import TextInput from '../components/input/TextInput'
import containerStore from '../store/apiStore/ContainerStore';
import galleryStore from '../store/apiStore/GalleryStore';
import { observer } from 'mobx-react-lite';
import useStore from '../store/RootStoreContext';
import EmptyTable from '../components/EmptyTable';

const ContainerPanel = () => {
    const {userStore} = useStore()

    const [openAlert, setOpenAlert] = useState(false)
    const [success, setSuccess] = useState(false)
    const [remove, setRemove] = useState(null)
    const [containers, setContainers] = useState([])
    const [container, setContainer] = useState(null)
    const [modal, setModal] = useState(false)
    const [statusContainer, setStatusContainer] = useState([])
    const [formData, setFormData] = useState({
        container_name: '',
        status: '',
        date: '',
        storage: '',
        file: null,
    })

    useEffect(() => {
        const fetchContainers = async () => {
            try {
                const response = await ContainerApi.getAll()
                const data = await response.data
                setContainers(data)
            } catch {

            }
        }
        const fetchData = async () => {
            try {
                const response = await ContainerApi.getStatus()
                const data = await response.data
                if (data) {
                    setStatusContainer(data)
                }

            } catch {
            }
        }
        fetchContainers()
        fetchData()
    }, [])

    const handleChange = (e) => {
        const { name, value, type } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: type === 'file' ? e.target.files[0] : value,
        }));
    };
    const handleRemoveContainer = async (container_id) => {
        if (success) {
            try {
                const response = await ContainerApi.delete(container_id)
                const data = await response.data
                if (data) {
                    const new_containers = containers.filter(container => container.id != container_id)
                    setContainers(new_containers)
                    setOpenAlert(false)
                }
            } catch {
                setOpenAlert(false)
                alert('ошибка удаления')
            }
        }
    };

    useEffect(() => {
        if (success) {
            handleRemoveContainer(remove)
        }
    }, [success])

    const handleSubmit = async (e) => {
        e.preventDefault();
        const queryParams = {
            container_name: formData.container_name,
            status_id: formData.status,
            delivery_time: formData.delivery_time,
            storage: formData.storage
        };
        try {
            const response = await ContainerApi.add(formData.file, queryParams)
            const { container, length } = response.data
            alert(`Добавлено ${length} обьектов в контейнер ${formData.container_name}`)
            const new_containers = [...containers, container]
            setContainers(new_containers)
            setModal(false)
        } catch {

        }
    };

    return (
        <>
            {modal &&
                <Modal setOpen={setModal}>
                    <Form onSubmit={handleSubmit}>
                        <div class="flex flex-col w-1/2 mx-auto gap-6 py-4">
                            <TextInput
                                name="container_name"
                                placeholder="название"
                                value={formData.title}
                                onChange={handleChange} />
                            <select
                                name="status"
                                className='modal__product_input'
                                value={formData.status}
                                defaultValue={statusContainer && statusContainer[0]?.id}
                                onChange={handleChange}
                            >
                                {statusContainer && statusContainer.map((container) => (
                                    <option key={container.id} value={container.id}>
                                        {container.status}
                                    </option>
                                ))}
                            </select>
                            <TextInput
                                name="delivery_time"
                                type="date"
                                value={formData.date}
                                onChange={handleChange}
                            />
                            <TextInput
                                name="storage"
                                placeholder="склад"
                                value={formData.storage}
                                onChange={handleChange}
                            />
                            <TextInput
                                name="file"
                                type="file"
                                onChange={handleChange}
                            />
                            <MainButton text={'добавить'} type='submit' />

                        </div>
                    </Form>
                </Modal>}
            {container && <ContainerModal container={container} setContainer={setContainer} status={statusContainer} />}
            <section className='my-4'>
                {openAlert && <Alert setOpen={setOpenAlert} text={'Вы точно хотите удалить контейнер и все его содержимое?'} setSuccess={setSuccess} />}
                <div class="container mx-auto">
                    <ul class="categories flex gap-6 items-center justify-start h-50 bg-white rounded-md">
                        <NavLink to='/containers' end className={({ isActive }) => `flex flex-col items-center group cursor-pointer hover:contrast-100 mx-2 ${isActive ? 'contrast-100' : 'contrast-0'} `}>
                            <img src={process.env.PUBLIC_URL + '/container.svg'} alt="category" className="category__svg h-16 w-16" />
                            <span className="text-center text-xl font-semibold text-black">все</span>
                            <span className="text-center text-sm font-semibold text-black">_</span>
                        </NavLink>
                        {containers && containers.map(container => (
                            <NavLink to={`/containers/${container.id}`} className={({ isActive }) => `flex flex-col min-w-28 hover:contrast-100 group relative items-center ${isActive ? 'contrast-100' : 'contrast-0'}`}>
                                <img src={process.env.PUBLIC_URL + '/container.svg'} alt="category" className="category__svg h-16 w-16" />
                                {userStore.checkAdmin() &&
                                    (<div className='absolute'>
                                        <span className='bg-gray-200 w-full p-1 hover:bg-gray-300 opacity-0 group-hover:opacity-90' onClick={() => { setContainer(container) }}>изменить</span>
                                        <span className='absolute opacity-0 group-hover:opacity-90' onClick={() => { setOpenAlert(true); setRemove(container.id) }}>&#10060;</span>
                                        {/* <span className='container__edit_icon' onClick={() => { setContainer(container) }}>&#10010;</span> */}
                                    </div>)}
                                <span className="text-center text-xl font-semibold text-black">{container.container_name}</span>
                                <span className="text-center text-sm font-semibold text-black">{formatDate(container.delivery_time)}</span>
                            </NavLink>
                        ))}
                        {userStore.checkAdmin() && (
                            <span onClick={() => setModal(true)} className="category__svg ms-4 cursor-pointer text-5xl contrast-0 hover:contrast-100" >&#43;</span>
                        )}

                    </ul>
                </div>
            </section>
        </>
    )
}

const ContainerModal = ({ container, setContainer, status }) => {
    const ref = useOutsideClick(() => setContainer(null));

    const handleSubmitData = async () => {
        try {
            const response = await ContainerApi.edit(container.id, container)
            console.log(response.data)
            alert('Данные обновлены. Перезагрузите страницу')
            setContainer(null)
        } catch (error) {
            alert(error)
        }
    }
    const handleChangeInput = (e) => {
        const { name, value } = e.target
        const newData = { ...container, [name]: value }
        setContainer(newData)
    }
    return (
        <Modal setOpen={setContainer}>
            <div class="flex flex-col gap-4 justify-center w-1/2 mx-auto">
                <div class="flex flex-col gap-4">
                    <input className='border-b-2 w-full text-2xl'
                        defaultValue={container.container_name}
                        name='container_name'
                        onChange={handleChangeInput} />
                    <div className='flex'>
                        <span className='min-w-36'>дата доставки:</span>
                        <input type='date' className='ms-2 border-b-2'
                            name='delivery_time'
                            defaultValue={container.delivery_time}
                            onChange={handleChangeInput} />
                    </div>
                    <div className='flex'>
                        <span className='min-w-36'>статус:</span>
                        <select className='ms-2 border-b-2' name='status_id' onChange={handleChangeInput}>
                            {status && status.map(st => (
                                <option key={st.id} value={st.id} >
                                    {st.status}
                                </option>
                            ))}
                        </select>
                    </div>
                    <div className='flex'>
                        <span className='min-w-36'>кол-во товаров::</span>
                        <span>{container.sum_quantity}</span>
                    </div>
                    <div className='flex'>
                        <span className='min-w-36'>cклад:</span>
                        <input className='ms-2 border-b-2'
                            defaultValue={container.storage}
                            name='storage'
                            onChange={handleChangeInput}
                        />
                    </div>
                </div>
                <div className='mx-auto'>
                    <MainButton text='сохранить' onClick={handleSubmitData} />
                </div>


            </div>
        </Modal>

    )
}


const AddToCart = observer(({ item }) => {

    const { preCartStore } = useStore()
    const [click, setClick] = useState(false)

    const orderItem = preCartStore.items.find(
        obj => obj.item_id == item.item_id && obj.container_id == item.container_id) ||
        { ...item, ['max_value']: item.quantity, ['quantity']: 0 }

    return (
        <div className="flex gap-1 h-10 w-16">
            <input
                type="text"
                className="w-5 border-b text-center outline-none bg-transparent"
                onChange={(e) => preCartStore.updateItemQuantity(orderItem, parseInt(e.target.value))}
                value={orderItem.quantity}
            />
            <button className="min-h-8 min-w-8" onClick={() => preCartStore.increment(orderItem)}>
                <img src="/images/profile/icon-cart.svg" className={`min-h-8 min-w-8 transition ease-in-out delay-100 hover:scale-110 hover:-translate-y-1 duration-300 ${click && "-translate-y-1 scale-110"}`} alt="icon-cart" />
            </button>
        </div>
    )
})

const ContainerDetail = observer(() => {

    const { item, cleanData } = containerStore
    const { images } = galleryStore

    const { preCartStore } = useStore()
    const orderItem = preCartStore.items.find(
        obj => obj.item_id == item.item_id && obj.container_id == item.container_id) ||
        { ...item, ['maxValue']: item.quantity, ['quantity']: 0 }

    return (
        <Modal setOpen={cleanData}>
            <div class="flex gap-8 border-b pb-4 pe-8">
                <ImageCard gallery={findAllMatches(images, item.pattern)} />
                <div class="flex-col">
                    <h2 className="font-medium text-2xl">
                        {item.title}
                    </h2>
                    <div className='flex gap-4 justify-between py-6 '>
                        <ModalBlock>
                            <ModalBlockElement name={'артикул'} value={item.article} />
                            <ModalBlockElement name={'бренд'} value={item.brand_name} />
                            <ModalBlockElement name={'тип'} value={item.type_name} />
                            <ModalBlockElement name={'кол-во'} value={item.quantity} />
                        </ModalBlock>
                        <ModalBlock>
                            <ModalBlockElement name={'контейнер'} value={item.container_name} />
                            <ModalBlockElement name={'дата'} value={item.delivery_time} />
                            <ModalBlockElement name={'статус'} value={item.status} />
                            <ModalBlockElement name={'на склад'} value={item.location} />
                        </ModalBlock>
                    </div>
                    <div className='flex'>
                        <div className='w-1/2 relative'>
                            {orderItem.quantity == 0 ? <MainButton text={'в корзину'}
                                onClick={() => preCartStore.increment(orderItem)} /> :
                                <IntegerFieldTest quantity={orderItem.quantity}
                                    decriment={() => preCartStore.decrement(orderItem)}
                                    increment={() => preCartStore.increment(orderItem)}
                                    onChange={(e) => preCartStore.updateItemQuantity(orderItem, parseInt(e.target.value))} />
                            }
                        </div>
                        <p class="font-medium text-3xl">{formatRub(item.price)}</p>
                    </div>
                </div>
            </div>
            <AdditionalAttributes info={item.ItemInfo} pattern={item.pattern} />
        </Modal>
    )
})


const Containers = observer((props) => {

    const containerId = useLoaderData() || null

    const [params, setParams] = useSearchParams()
    const { items, pages, types, brands, isLoading, fetchContainerFromAPI, fetchFilterFromAPI } = containerStore
    const { images, fetchGalleryFromAPI } = galleryStore
    const { item, fetchItemFromAPI } = containerStore
    const { preCartStore } = useStore()

    useEffect(() => {
        fetchGalleryFromAPI()
        fetchContainerFromAPI()
        fetchFilterFromAPI()
        preCartStore.fetchPreCartFromAPI()
    }, []);

    useEffect(()=>{
            fetchContainerFromAPI(containerId, params)
    }, [containerId, params])


    const handleOpenItem = async (event, item_id) => {
        const target = event.target;
        if (target.tagName === 'TD' && target.cellIndex !== target.parentElement.cells.length - 1) {
            fetchItemFromAPI(item_id)
        }
    }
    return (
        <>
            {item && <ContainerDetail />}
            <Search />
            <Breadcrumbs />
            <ContainerPanel />
            <section class="catalog">
                <div class="container mx-auto">
                    <div class="flex gap-10">
                        <Filters action={'/containers'} types={types} brands={brands} />
                        <Table Pagination={<Pagination total={pages} />}>
                            <Thead>
                                <TableCell>артикул</TableCell>
                                <TableCell className={'w-14'}></TableCell>
                                <TableCell>название</TableCell>
                                <TableCell>тип</TableCell>
                                <TableCell className={'text-nowrap'}>кол-во</TableCell>
                                <TableCell>цена</TableCell>
                                <TableCell>статус</TableCell>
                                <TableCell>дата</TableCell>
                                <TableCell></TableCell>
                            </Thead>
                            {isLoading ?
                            <EmptyTable row={15} col={8}/>
                            : items.map((item, index) => (
                                <TableRow onClick={(e) => handleOpenItem(e, item.item_container_id)}>
                                    <TableCell>
                                        <span>{item.brand_name}</span>
                                        <p>{item.article}</p>
                                    </TableCell>
                                    <TableCell>
                                        <img class="h-16 w-16" src={findFirstMatch(images, item.pattern)?.image_min || '/default_tire.svg'} alt="" />
                                    </TableCell>
                                    <TableCell>{item.title}</TableCell>
                                    <TableCell>{item.type_name}</TableCell>
                                    <TableCell>{item.quantity > 10 ? '>10' : item.quantity}</TableCell>
                                    <TableCell>{formatRub(item.price)}</TableCell>
                                    <TableCell>{item.status}</TableCell>
                                    <TableCell>{formatDate(item.delivery_time)}</TableCell>
                                    <TableCell>
                                        <AddToCart item={item} />
                                    </TableCell>
                                </TableRow>)
                            )}
                        </Table>
                    </div>
                </div>
            </section>
        </>
    );
})


export default Containers;
