import React from 'react';
import { TableCell, TableRow } from './table/Table';

const EmptyTable = ({ row, col }) => {
    const range = (n) => {
        return Array.from({ length: n }, (_, i) => i);
    }
    return (
        <>
            {
                range(row).map(obj => (
                    <TableRow className={'animate-pulse'}>
                        {range(col).map(obj => (
                            <TableCell><div className='w-full bg-gray-200 rounded-md h-6'></div></TableCell>
                        ))}  
                    </TableRow>)
                )
            }
        </>
    )
}

export default EmptyTable;