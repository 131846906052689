import { AuthApi } from "../../api"
import { makeAutoObservable } from 'mobx';


export class UserStore {
    user = null
    person = null
    manager = null
    company = null
    token = localStorage.getItem("token")
    loading = true
    modal = false
    message = ''
    modalLoading = false
    modalError = ''

    constructor() {
        makeAutoObservable(this);
    }

    setUser = (data) => {
        this.user = data
        this.person = data.person
        this.manager = data.manager
        this.company = data.company
    }

    setLoading = (bool) => {
        this.loading = bool
    }

    openModal = () => {
        this.modal = true
    }

    closeModal = () => {
        this.modal = false
    }

    checkActive = () => {
        if (this.user) {
            return this.user.is_active
        }
        return false
    }

    checkStaff = () => {
        if (this.user) {
            return this.user.is_staff || this.user.is_admin
        }
        return false
    }

    checkAdmin = () => {
        if (this.user) {
            return this.user.is_admin
        }
        return false
    }
    fetchChangeProfile = async (profile) => {
        try {
            const response = await AuthApi.changeProfile(profile)
            const data = response.data
            this.setUser(data)
        } catch {
            console.log('fail response')
        }
    }

    fetchGetUser = async () => {
        try {
            this.setLoading(true)
            const response = await AuthApi.me(this.token)
            this.setLoading(false)
            this.closeModal()
            const data = response.data
            this.setUser(data)
        } catch {
            this.loading = false
            console.error('error fetch user')
        }
    }
    registration = async (reg, setMessage) => {
        try {
            this.modalLoading = true
            const response = await AuthApi.registration(reg)
            setMessage('Успешная регистрация! Менеджер свяжется с вами')
            this.modalError = ''
        } catch (error) {
            this.modalError = error.response.data.detail
        }
        this.modalLoading = false
    }


    login = async (email, password) => {
        try {
            this.modalLoading = true
            const response = await AuthApi.login(email, password)
            const data = response.data
            const localStoragePromise = new Promise((resolve) => {
                localStorage.setItem("token", data.access_token);
                resolve();
            });
            await localStoragePromise;
            this.token = data.access_token
            await this.fetchGetUser()
            this.modalLoading = false
            this.modalError = ''
        } catch (error) {
            this.modalLoading = false
            this.modalError = error.response.data.detail
        }
    }

    logout = () => {
        this.token = null
        this.user = null
        localStorage.removeItem('token')
    }
}

export default UserStore;