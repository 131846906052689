import CartStore from "./apiStore/CartStore"
import PreCartStore from './apiStore/PreCartStore'
import UserStore from "./apiStore/UserStore"

export class RootStore {
    cartStore
    preCartStore
    userStore

    constructor() {
        this.cartStore = new CartStore(this)
        this.preCartStore = new PreCartStore(this)
        this.userStore = new UserStore(this)
    }
}

const rootStore = new RootStore();
export default rootStore;