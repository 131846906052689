import { OrderApi, PreOrderApi } from "../../api"
import { makeAutoObservable } from 'mobx';

export class ClientCartStore {
    order = null
    preOrder = null
    items = []
    preItems = []
    totalItems = 0;
    totalPreItems = 0
    loading = false

    constructor() {
        makeAutoObservable(this);
    }

    setLoading = (bool) => {
        this.loading = bool
    }

    cleanOrder = () => {
        this.items = []
        this.order = null
        this.totalItems = 0
    }
    cleanPreOrder = () => {
        this.preItems = []
        this.preOrder = null
        this.totalPreItems = 0
    }

    setItems = (data) => {
        this.order = data
        this.items = data.items
    }

    setPreItems = (data) => {
        this.preOrder = data
        this.preItems = data.items
    }

    fetchGetCart = async (client_id) => {
        this.setLoading(true)
        try {
            const response = await OrderApi.getCart(client_id)
            this.setItems(response.data)
            this.calculateTotalItems()
        } catch {
            this.cleanOrder()
            console.error('error load cart')
        }
        this.setLoading(false)
    }
    fetchGetPreCart = async (client_id) => {
        this.setLoading(true)
        try {
            const response = await PreOrderApi.getCart(client_id)
            this.setPreItems(response.data)
            this.calculateTotalPreItems()
        } catch {
            this.cleanPreOrder()
            console.error('error load preCart')
        }
        this.setLoading(false)
    }


    calculateTotalItems() {
        this.totalItems = this.items.reduce((acc, item) => acc + (item.quantity * item.price), 0);
    }
    calculateTotalPreItems() {
        this.totalPreItems = this.preItems.reduce((acc, item) => acc + (item.quantity * item.price), 0);
    }

}

const clientCartStore = new ClientCartStore()
export default clientCartStore