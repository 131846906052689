import { makeAutoObservable } from 'mobx';
import { OrderApi } from '../../api';

export class OrderItemsStore {
    items = []
    prevItems = []
    order = {}
    isLoading = false
    waiting = false

    constructor() {
        makeAutoObservable(this);
    }

    setWaiting = (bool) => {
        this.waiting = bool
    }

    setLoading = (bool) => {
        this.isLoading = bool
    }

    setOrder = (data) => {
        this.items = data.items
        this.prevItems = [...data.items]
        this.order = data
    }

    cleanData = () => {
        this.items = []
        this.prevItems = []
        this.order = {}
    }

    increment = (item) => {
        this.updateItemQuantity(item, item.quantity + 1)
    }

    decrement = (item) => {
        this.updateItemQuantity(item, item.quantity - 1)
    }

    compareLists = () => {
        const compareObjects = (obj1, obj2) => {
            if (obj1.quantity !== obj2.quantity) {
                return false
            }
            return true;
        }


        if (this.items.length !== this.prevItems.length) {
            return false;
        }

        for (let i = 0; i < this.items.length; i++) {
            const obj1 = this.items[i];
            const obj2 = this.prevItems[i];

            if (!compareObjects(obj1, obj2)) {
                return false;
            }
        }
        return true;
    }

    sendChangeOrder = async (setMessage, setLoading) => {
        let data = {}
        this.items.map(item => { data[item.item_order_id] = item.quantity })
        try {
            setLoading(true)
            const response = await OrderApi.sendChanged(data, this.order.id)
            setLoading(false)
            const copiedItems = this.items.map(item => ({ ...item }));
            this.prevItems.replace(copiedItems);
            setMessage('Заказ изменен! Ожидайте подтверждения')
        } catch {

            setLoading(false)
            setMessage('Ошибка! Попробуйте позже или свяжитесь с менеджером')
            console.error('error')
        }
    }

    returnChange = () => {
        const copiedItems = this.prevItems.map(item => ({ ...item }));
        this.items.replace(copiedItems);
    }

    removeItemFromCart = (item) => {
        this.items = this.items.filter(
            i => i.item_id !== item.item_id || i.storage_id !== item.storage_id || i.container_id !== item.container_id);
        // this.sendQuantityUpdateToAPI(item, 0)
    }
    updateItemQuantity = (item, newQuantity) => {
        if (newQuantity > item.maxValue || newQuantity > item.max_value || newQuantity < 0) {
            return
        }
        if (newQuantity == 0) {
            this.removeItemFromCart(item)
            return
        }

        // this.sendQuantityUpdateToAPI(item, newQuantity);

        const existingItemIndex = this.items.findIndex(i => {
            if (item.container_id) {
                // Если задан container_id, проверяем  его
                return i.item_id === item.item_id &&
                    i.container_id === item.container_id;
            } else {
                // Если container_id не задан, проверяем только storage_id
                return i.item_id === item.item_id &&
                    i.storage_id === item.storage_id;
            }
        });
        if (existingItemIndex !== -1) {
            this.items[existingItemIndex].quantity = newQuantity;
        }
    }

    // async sendQuantityUpdateToAPI(item, newQuantity) {
    //     try {
    //         //пример const data = {'item_id': 93, 'quantity': 1, 'storage_id': 1, 'price': 1000}
    //         const data = {
    //             item_id: item.item_id,
    //             quantity: newQuantity,
    //             storage_id: item.storage_id ? item.storage_id : null,
    //             container_id: item.container_id ? item.container_id : null,
    //             price: item.price
    //         }
    //         const response = await OrderApi.editItemOrder(data, item.item_order_id)
    //     } catch {

    //     }
    // }

    fetchItemFromAPI = async (order_id) => {
        try {
            this.setLoading(true)
            const response = await OrderApi.getItems(order_id)
            this.setOrder(response.data)

        } catch (error) {
            console.error('Error fetching cart:', error);
        }
        this.setLoading(false)
    }
}

export class OrdersStore {
    orders = []
    preOrders = []
    notDoneOrders = []
    canceledOrders = []
    statuses = []
    waiting = false

    constructor() {
        makeAutoObservable(this);
    }

    setWaiting = (bool) => {
        this.waiting = bool
    }

    cancelOrder = async (order, setWaiting) => {
        setWaiting(true)
        try {
            const response = await OrderApi.cancelOrder(order)
            this.fetchGetOrders()
        } catch {
            console.error('error cancel order')
        }
        setWaiting(false)
    }

    changeStatus = async (order, status_id) => {
        try {
            const response = await OrderApi.changeStatus(order.id, status_id)
            this.fetchGetOrders()
        } catch (error) {
            console.log('error change status')
        }
    }

    setStatuse = (data) => {
        this.statuses = data
    }

    setData = (data) => {
        this.orders = data.filter(obj => obj.pre_order == false && obj.step != -2)
        this.preOrders = data.filter(obj => obj.pre_order == true && obj.step != -2)
        this.canceledOrders = data.filter(obj => obj.step == -2)
        this.notDoneOrders = data.filter(obj => obj.step > 1 && obj.step < 6)
    }


    fetchGetStatus = async () => {
        try {
            const response = await OrderApi.getStatus()
            this.setStatuse(response.data)
        } catch {
            console.error('erorr loaded statuses')
        }
    }

    fetchGetOrders = async () => {
        try {
            const response = await OrderApi.getOrders()
            this.setData(response.data)
        } catch {
            console.error('error loading orders')
        }
    }
}

export const ordersStore = new OrdersStore()

export const orderItemsStore = new OrderItemsStore()