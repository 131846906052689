import { useState } from "react";
import { useOutsideClick } from "../../utils";

const Dropdown = ({ button, className, children }) => {
    const [dropdown, setDropdown] = useState(false)
    const ref = useOutsideClick(() => setDropdown(false));
    return (
        <div class="relative inline-block text-left" ref={ref}>
            {button && <div>
                <button type="button" 
                    onClick={()=>setDropdown(!dropdown)}
                    class="inline-flex w-full justify-center gap-x-1.5 bg-transparent px-3 py-2 text-sm font-semibold text-gray-900" id="menu-button" aria-expanded="true" aria-haspopup="true">
                    {button}
                </button>
            </div>}
            {dropdown && (
                <div  class={`${className} absolute right-0 z-10 mt-2 w-56 origin-top-right  bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none`}>
                <div class="py-1" role="none">
                   {children}
                </div>
            </div>
            )}
            
        </div>
    )
}

export default Dropdown