import { useState } from "react";
import MainButton from "../components/button/MainButton";
import TextInput from "../components/input/TextInput"
import useStore from "../store/RootStoreContext";
import { observer } from "mobx-react-lite";

const Profile = observer(() => {
    const {userStore} = useStore()
    const {user, manager, person, company, fetchChangeProfile} = userStore

    const [profile, setProfile] = useState({
        id: user.id,
        fio: person?.fio,
        email: user.email,
        phone_number: person?.phone_number,
        city: person?.city || null,
        company_name: company?.company_name || null,
        inn: company?.inn || null,
        comment: person?.comment || null
    })


    const handleChangeProfile = (e) => {
        let { name, value } = e.target
        if (value == undefined || value == '') {
            value = null
        }
        setProfile(prevData => ({ ...prevData, [name]: value }))
    }

    return (
        <section class="profile">
            <div class="container mx-auto">
                <div class="profile__inner">
                    <div class="mt-10 mb-10">
                        <form class="profile__change" action="submit">
                            <div className='flex justify-between mb-10 items-center'>
                                <h2 class="font-medium text-2xl">Ваши данные</h2>
                                {manager && (
                                <div class="flex items-center gap-4">
                                    <img className='w-10 h-10' src="images/profile/whatsapp.svg" alt="whatsapp" />
                                    
                                        <div class="search__main-info">
                                            <p class="text-gray-500 font-normal">Ваш&nbsp;менеджер&nbsp;— {manager?.person?.fio}</p>
                                            <p class="text-gray-500 font-normal">
                                                {manager?.person?.phone_number}, {manager?.email}
                                            </p>
                                        </div>
                                </div>
                                )}
                            </div>

                            <div class="flex gap-10 mb-16 w-2/3">
                                <div class="flex flex-col gap-10 w-full">
                                    <TextInput type="text" placeholder="ФИО" name="fio" value={profile.fio} onChange={handleChangeProfile} />
                                    <TextInput type="tel" placeholder="номер телефона" name="phone_number" value={profile.phone_number} onChange={handleChangeProfile} />
                                    <TextInput type="text" autocomplete="email" placeholder="email" name="email" value={profile.email} onChange={handleChangeProfile} />
                                    <TextInput type="text" placeholder="Санкт-Петербург" name="city" value={profile.city} onChange={handleChangeProfile} />
                                </div>
                                <div class="flex flex-col gap-10 w-full">
                                    <TextInput type="text" placeholder="компания" name="company_name" value={profile.company_name} onChange={handleChangeProfile} />
                                    <TextInput type="text" placeholder="инн" name="inn" value={profile.inn} onChange={handleChangeProfile} />
                                    <textarea className="w-full h-full border outline-none py-3 px-6 text-xl focus:border-gray-600" onChange={handleChangeProfile}
                                        placeholder='комментарий' name="comment" value={profile.comment}></textarea>
                                </div>
                            </div>
                            <MainButton text={'сохранить изменения'} onClick={()=>fetchChangeProfile(profile)} />
                        </form>
                    </div>
                </div>
            </div>
        </section>
    )
})

export default Profile;