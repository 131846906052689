import  {NavLink, useMatches}  from 'react-router-dom'

export default function Breadcrumbs() {
    const matches = useMatches()
    let crumbs = matches
        .filter((match) => Boolean(match.handle?.crumb))
        .map((match) => match.handle.crumb(match.data));
    return (
        <section className="breadcrumbs py-2" >
                <div className="container mx-auto">
                    <div className="flex items-center">
                        {crumbs.map((crumb, index) => (
                            <>
                                <NavLink className={({isActive}) => `text-lg text-gray-500 px-2 ${isActive ? "text-black" : ""} `} 
                                    key={index}>{crumb}</NavLink>
                                {index !== crumbs.length - 1 && <span className="text-gray-500">&gt;</span>}
                            </>
                        ))}

                    </div>
                </div>
            </section>
    )
}