import { makeAutoObservable } from 'mobx';
import { PreOrderApi, OrderApi } from '../../api'

export class PreCartStore {
    items = [];
    totalAmount = 0;
    count = 0
    isLoading = false
    pendingChanges = new Map();
    timeoutId

    constructor() {
        makeAutoObservable(this);
    }

    setLoading = (bool) => {
        this.isLoading = bool
    }

    setItems = ({items}) => {
        this.items = items
    }

    fetchPreCartFromAPI = async () => {
        try {
            this.setLoading(true)
            const response = await PreOrderApi.getCart();
            this.setItems(response.data)
            this.calculateTotalAmount()
            this.calculateCount()
        } catch (error) {
            console.error('Error fetching cart:', error);
        }
        this.setLoading(false)
    }
    cleanData = () => {
        this.items = [];
        this.totalAmount = 0;
        this.count = 0
    }

    sendCart = async (setMessage, setLoading, navigate) => {
        try {
            setLoading(true)
            const response = await PreOrderApi.send()
            setLoading(false)
            setMessage('Предзаказ отправлен, в ближайшее время с Вами свяжется менеджер')
            this.cleanData()
            navigate("/orders");
        } catch {
            setMessage('Ошибка! Предзаказ не отправлен')
        }
    }

    addItemToCart(item, newQuantity) {
        this.items.push({...item, ['quantity']: newQuantity})
        this.calculateCount()
        this.calculateTotalAmount()
    }

    increment = (item) => {
        this.updateItemQuantity(item, item.quantity + 1)
    }

    decrement = (item) => {
        this.updateItemQuantity(item, item.quantity - 1)
    }

    removeItemFromCart = (item) => {
        this.items = this.items.filter(i => i.item_id !== item.item_id || i.container_id !== item.container_id);
        this.sendQuantityUpdateToAPI(item, 0)
        this.calculateTotalAmount();
        this.calculateCount()
    }

    updateItemQuantity = (item, newQuantity) => {
        if (item.maxValue < newQuantity || item.max_value < newQuantity || newQuantity < 0){
            return
        }
        if (newQuantity == 0){
            this.removeItemFromCart(item)
            return
        }
        if (this.pendingChanges.has(`${item.item_id}/${item.container_id}`)) {
            clearTimeout(this.timeoutId)
        }
        this.pendingChanges.set(`${item.item_id}/${item.container_id}`, newQuantity);
        this.timeoutId = setTimeout(() => {
            const quantity = this.pendingChanges.get(`${item.item_id}/${item.container_id}`);
            this.pendingChanges.delete(`${item.item_id}/${item.container_id}`);
            this.sendQuantityUpdateToAPI(item, quantity);
        }, 1000);
        
        // Update local state immediately to reflect changes
        const existingItemIndex = this.items.findIndex(i => i.item_id === item.item_id && i.container_id == item.container_id);
        if (existingItemIndex !== -1) {
            this.items[existingItemIndex].quantity = newQuantity;
        }else{
            this.addItemToCart(item, newQuantity)
        }
        this.calculateTotalAmount();
    }

    async sendQuantityUpdateToAPI(item, newQuantity) {
        try {
            const data = {
                item_id: item.item_id,
                quantity: newQuantity,
                container_id: item.container_id,
                price: item.price 
            }
            const response = await OrderApi.addEdit(data)
        } catch {

        }
    }

    calculateTotalAmount() {
        this.totalAmount = this.items.reduce((acc, item) => acc + (item.quantity * item.price), 0);
    }
    calculateCount (){
        this.count = this.items.length
    }
}

export default PreCartStore;
