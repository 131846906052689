import React from 'react';
import { Outlet } from 'react-router-dom';
import Footer from '../components/Footer';
import Header from '../components/Header';

function Root(props) {
    return (
        
            <div className='min-h-screen flex flex-col justify-between'>
                <div>
                    <Header />
                    <main className='main'>

                        <Outlet />
                    </main></div>

                <Footer />
            </div>
    );
}

export default Root;