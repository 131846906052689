import React, { useEffect, useState } from 'react';
import { Form, useSearchParams } from 'react-router-dom';
import { StoreApi } from '../api';

const Toggle = ({ title, children }) => {
  const [close, setClose] = useState(false)

  return (
    <div class="flex flex-col">
      <button
        type='button'
        onClick={() => setClose(!close)}
        className="items-center py-2 border-b border-gray-400 flex justify-between" aria-expanded="true">
        {title}
        <img className={!close && 'rotate-180'} src="/images/profile/toggle.svg" alt="" focusable="false" />
      </button>
      <div className={close && 'hidden'}>
        {children}
      </div>
    </div>
  )
}

const Checkbox = ({ name, value, title, onChange, checked }) => (
  <label className="flex gap-2 items-center text-lg text-gray-600 cursor-pointer">
    <input className="w-4 h-4 cursor-pointer appearance-none border-2 bg-clip-content p-[2px] checked:bg-gray-800"
      name={name}
      value={value}
      onChange={onChange}
      checked={checked}
      type="checkbox" />
    <span class="">{title}</span>
  </label>
)

const SortButton = ({ title, lower, value, checked, onClick }) => {

  return (
    <div className={`flex gap-2 cursor-pointer items-center ${checked && 'active'}`} onClick={onClick}>
      <input type='radio' name='sort' value={value} checked={checked} hidden></input>
      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="18" viewBox="0 0 16 18">
        {lower ?
          <>
            <rect width="7.27273" height="4.15385" fill={checked ? '#fddb8d' : "#86827A"} />
            <rect y="6.92285" width="11.6364" height="4.15385" fill={checked ? '#fddb8d' : "#86827A"} />
            <rect y="13.8467" width="16" height="4.15385" fill={checked ? '#fddb8d' : "#86827A"} />
          </> :
          <>
            <rect width="7.27273" height="4.15385" transform="matrix(1 0 0 -1 0 18.001)" fill={checked ? '#fddb8d' : "#86827A"} />
            <rect width="11.6364" height="4.15385" transform="matrix(1 0 0 -1 0 11.0781)" fill={checked ? '#fddb8d' : "#86827A"} />
            <rect width="16" height="4.15385" transform="matrix(1 0 0 -1 0 4.1543)" fill={checked ? '#fddb8d' : "#86827A"} />
          </>
        }
      </svg>
      <p>{title}</p>
    </div>
  )

}


const Filters = ({ action, types, brands, storages }) => {

  const [searchParams, setSearchParams] = useSearchParams();
  const [sortLower, setSortLower] = useState(false);
  const [sortHigher, setSortHigher] = useState(false);

  const handleReset = () => {
    setSortHigher(false)
    setSortLower(false)
    setSearchParams('')
  }

  const handleChangePrice = (event) => {
    let { name, value } = event.target
    searchParams.set('page', 1)
    if (searchParams.has(name, value)) {
      searchParams.append(name, value)
    } else {
      searchParams.set(name, value)
    }
    setSearchParams(searchParams)
  }

  const handleChange = (event) => {
    let { name, value, checked } = event.target
    searchParams.set('page', 1)
    if (checked) {
      searchParams.append(name, value)
      
      setSearchParams(searchParams)
    } else {
      searchParams.delete(name, value)
      setSearchParams(searchParams)
    }
  }

  const handleLowerPrice = () => {
    setSortHigher(false)
    setSortLower(true)
    searchParams.delete('sort');
    searchParams.append('sort', 'price')
    setSearchParams(searchParams)
  }

  const handleHigherPrice = () => {
    setSortLower(false)
    setSortHigher(true)
    searchParams.delete('sort')
    searchParams.append('sort', '-price')
    setSearchParams(searchParams)
  }

  return (

    <div className="w-1/4">
      <Form action={action} method='get'>
        <div className="border-b-2 flex justify-between border-my-yellow pb-2 px-2 relative">
          <span>
            фильтры
          </span>
          <span className='cursor-pointer text-gray-400 hover:text-gray-800' onClick={handleReset}>сбросить</span>
        </div>
        <div className="flex justify-between py-4 px-2 items-center">
          <SortButton
            title={'дешевле'}
            lower={true}
            value={'price'}
            checked={sortLower}
            onClick={handleLowerPrice} />
          <SortButton
            title={'дороже'}
            lower={false}
            value={'-price'}
            checked={sortHigher}
            onClick={handleHigherPrice} />
        </div>
        <Toggle title={'цена'}>
          <div className="flex justify-between items-center py-4">
            <p>от</p>
            <input type="text" class="w-[80px] p-2 outline-none border border-gray-200" name='price_gte' value={searchParams.get('price_gte') || ''} onChange={handleChangePrice} />
            <p>до</p>
            <input type="text" class="w-[80px] p-2 outline-none border border-gray-200" name='price_lte' value={searchParams.get('price_lte') || ''} onChange={handleChangePrice} />
          </div>
        </Toggle>
        {types && <Toggle title={'тип'}>
          <div class="flex flex-col gap-2 py-2"> 
              {types.map(type =>
                <Checkbox
                  name={'type'}
                  value={type.id}
                  title={type.type_name}
                  checked={searchParams.has('type', type.id)}
                  onChange={handleChange} />)}
          </div>
        </Toggle>}
        {brands && <Toggle title={'бренд'}>
          <div class="flex flex-col gap-2 py-2">
              {brands.map(brand =>
                <Checkbox
                  name={'brand'}
                  title={brand.brand_name}
                  value={brand.id}
                  onChange={handleChange}
                  checked={searchParams.has('brand', brand.id)} />)}
          </div>
        </Toggle>}
        {storages && <Toggle title={'склад'}>
          <div class="flex flex-col gap-2 py-2">
            {storages.map(storage =>
              <Checkbox
                name={'location'}
                title={storage.storage_name}
                value={storage.id}
                onChange={handleChange}
                checked={searchParams.has('location', storage.id)} />)}
          </div>
        </Toggle>}
      </Form>
    </div>
  )
};

export default Filters;
