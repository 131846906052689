import { NavLink } from "react-router-dom";

const LinkNavigation = ({title, link, className}) => {
    return (
        <li className={`text-[#f8f5f0] flex h-16 ${className}`}>
            <NavLink to={link} className={({ isActive }) => `p-4 hover:border-b-4 hover:border-yellow-400 ${isActive ? "bg-yellow-400 text-black" : ""}`}>{title}</NavLink>
        </li>
    )
}

export default LinkNavigation;