import { Navigate } from "react-router-dom";
import Root from "./pages/Root";
import useStore from "./store/RootStoreContext";
import { observer } from "mobx-react-lite";
import About from "./pages/Home";
import HomePage from "./pages/HomePage";


export const HomePageRoute = observer(() => {
  const { userStore } = useStore();

  if (!userStore.checkActive()) {
    userStore.openModal()
    return <About />
  }
  return <HomePage />

})

export const ProtectedRoute = observer(() => {
  const { userStore } = useStore();

  if (!userStore.checkActive()) {
    userStore.openModal()
    return <Navigate to="/" />;
  }
  return <Root />

});

export const ManagerRoute = observer(() => {
  const { userStore } = useStore();

  if (!userStore.checkStaff()) {
    return <Navigate to="/" />;
  }
  return <Root />

});
export const AdminRoute = observer(() => {
  const { userStore } = useStore();

  if (!userStore.checkAdmin()) {
    return <Navigate to="/" />;
  }
  return <Root />

});