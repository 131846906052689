import { makeAutoObservable } from 'mobx';
import { StoreApi } from '../../api';

export class MarketStore {
    items = []
    item = null
    pages = 0
    brands = []
    types = []
    storages = []
    isLoading = false

    constructor() {
        makeAutoObservable(this);
    }

    setLoading = (bool) => {
        this.isLoading = bool
    }

    setFilters = ({ brands, types, storages }) => {
        this.brands = brands
        this.types = types
        this.storages = storages
    }

    setItems = ({items, total_page}) => {
        this.items = items;
        this.pages = total_page
    }

    setItem = (data) => {
        this.item = data
    }

    cleanData = () => {
        this.item = null
    }

    fetchItemFromAPI = async (item_id) => {
        try {
            const response = await StoreApi.get(item_id)
            this.setItem(response.data)
        } catch (error) {
            console.error('Error fetching cart:', error);
        }
    }

    fetchMarketFromAPI = async (params) => {
        try {
            this.setLoading(true)
            const response = await StoreApi.getItems(params);
            this.setItems(response.data)
        } catch (error) {
            console.error('Error fetching cart:', error);
        }
        this.setLoading(false)
    }
    fetchFilterFromAPI = async () => {
        try {
            const response = await StoreApi.getFilters();
            this.setFilters(response.data)
        } catch (error) {
            // Обработка ошибок, если необходимо
            console.error("Error fetching data:", error);
        }
    }

}

const marketStore = new MarketStore();
export default marketStore