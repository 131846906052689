import { makeAutoObservable } from 'mobx';
import { OrderApi } from '../../api'

class CartStore {
    items = [];
    totalAmount = 0;
    count = 0
    pendingChanges = new Map();
    timeoutId

    constructor() {
        makeAutoObservable(this);
    }

    setItems = ({items}) => {
        this.items = items
    }

    fetchCartFromAPI = async () => {
        try {
            const response = await OrderApi.getCart();
            this.setItems(response.data)
            this.calculateTotalAmount()
            this.calculateCount()
        } catch (error) {
            console.error('Error fetching cart:', error);
        }
    }

    cleanData = () => {
        this.items = [];
        this.totalAmount = 0;
        this.count = 0
    }

    addItemToCart(item, newQuantity) {
        this.items.push({ ...item, ['quantity']: newQuantity })
    }

    increment = (item) => {
        this.updateItemQuantity(item, item.quantity + 1)
    }

    decrement = (item) => {
        this.updateItemQuantity(item, item.quantity - 1)
    }

    removeItemFromCart = (item) => {
        this.items = this.items.filter(i => i.item_id !== item.item_id || i.storage_id !== item.storage_id);
        this.sendQuantityUpdateToAPI(item, 0)
        this.calculateTotalAmount();
        this.calculateCount()
    }

    updateItemQuantity = (item, newQuantity) => {
        if (newQuantity > item.maxValue || newQuantity > item.max_value || newQuantity < 0) {
            return
        }
        if (newQuantity == 0) {
            this.removeItemFromCart(item)
            return
        }
        if (this.pendingChanges.has(`${item.item_id}/${item.storage_id}`)) {
            clearTimeout(this.timeoutId)
        }
        this.pendingChanges.set(`${item.item_id}/${item.storage_id}`, newQuantity);
        this.timeoutId = setTimeout(() => {
            const quantity = this.pendingChanges.get(`${item.item_id}/${item.storage_id}`);
            this.pendingChanges.delete(`${item.item_id}/${item.storage_id}`);
            this.sendQuantityUpdateToAPI(item, quantity);
        }, 1000);

        // Update local state immediately to reflect changes
        const existingItemIndex = this.items.findIndex(i => i.item_id === item.item_id && i.storage_id == item.storage_id);
        if (existingItemIndex !== -1) {
            this.items[existingItemIndex].quantity = newQuantity;
        } else {
            this.addItemToCart(item, newQuantity)
        }
        this.calculateTotalAmount();
        this.calculateCount()
    }

    sendCart = async (setMessage, setLoading, navigate) => {
        try {
            setLoading(true)
            const response = await OrderApi.send()
            setLoading(false)
            setMessage('Заказ отправлен, в ближайшее время с Вами свяжется менеджер')
            this.cleanData()
            navigate("/orders");
        } catch {
            setLoading(false)
            setMessage('Ошибка! Заказ не отправлен')
        }
    }

    async sendQuantityUpdateToAPI(item, newQuantity) {
        try {
            //пример const data = {'item_id': 93, 'quantity': 1, 'storage_id': 1, 'price': 1000}
            const data = {
                item_id: item.item_id,
                quantity: newQuantity,
                storage_id: item.storage_id,
                price: item.price
            }
            const response = await OrderApi.addEdit(data)
        } catch {

        }
    }

    calculateTotalAmount() {
        this.totalAmount = this.items.reduce((acc, item) => acc + (item.quantity * item.price), 0);
    }
    calculateCount() {
        this.count = this.items.length
    }
}

export default CartStore;
