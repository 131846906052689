import React from 'react';
import { Link } from 'react-router-dom';

function Footer(props) {
    return (
        <footer className="bg-[#f8f5f0] py-4 m-0 w-full">
            <div className="container mx-auto">
                <div className="flex justify-between md:flex-row  flex-col ">
                    <div className="text-[#86827a] text-2xl">
                        <Link to='' className="flex justify-center">
                            <img src={process.env.PUBLIC_URL + "/baltyre.svg"}  className='p-2 flex justify-center' style={{'width': '200px', maxWidth:'200px'}} alt="logo" />
                        </Link>
                        <p className="p-2 flex justify-center">© 2024 Балтайр</p>
                    </div>
                    <nav className="text-[#86827a] text-2xl">
                        <ul className="p-2 flex justify-center">
                        </ul>
                    </nav>
                    <nav className="text-[#86827a] text-2xl">
                        <ul className="p-2 flex justify-center">
                        </ul>
                    </nav>
                    <nav className="text-[#86827a] text-2xl">
                        <ul className="footer__list">
                            <li className="p-2 flex justify-center">
                                <Link href="tel:+79213103810" className="flex justify-center">+7 (812) 317-50-12
                                </Link>
                            </li>
                            <li className="p-2">
                                <Link href="#" className="flex justify-center">office@baltyre.ru</Link>
                            </li>
                        </ul>
                    </nav>
                </div>
                {/* <div className="footer__inner-mobile">
                    <div className="footer__info">
                        <Link href="#" className="footer__logo-img"><img src={process.env.PUBLIC_URL + "/baltyre.svg"} alt="logo" /></Link>
                        <div className="footer__info-content">
                            <p className="footer__info-phone">+7 (812) 317-50-12</p>
                            <p className="footer__info-email">office@baltyre.ru</p>
                        </div>
                    </div>
                    <nav className="footer__menu">
                        <ul className="footer__list">
                            
                        </ul>
                        <ul className="footer__list">
                            
                        </ul>
                    </nav>
                    <p className="footer__logo-copyright">© 2024 Балтайр</p>
                </div> */}
            </div>
        </footer>
    );
}

export default Footer;
{/* <footer className="p-4 bg-gray-100">
            <div className='container mx-auto'>


                <div className="flex text-xl justify-between">
                    <div className="footer__logo">
                        <Link to="" className=""><img className='w-[220px] mb-6 bg-gray-100' src={process.env.PUBLIC_URL + "/logo-baltyre.png"} alt="logo"></img></Link>
                        <p className="ps-2">© 2023 Балтайр СПБ</p>
                    </div>
                    <nav className="footer__menu">
                        <ul className="footer__list">
                            <li className="m-2">
                                <a href="/about" className="footer__list-link">о компании</a>
                            </li>
                            <li className="m-2">
                                <a href="/catalog/" className="footer__list-link">каталог</a>
                            </li>
                            <li className="m-2">
                                <a href="/delivery" className="footer__list-link">доставка и оплата</a>
                            </li>
                        </ul>
                    </nav>
                    <nav className="footer__menu">
                        <ul className="footer__list">
                            <li className="m-2">
                                <a href="/news" className="footer__list-link">новости</a>
                            </li>
                            <li className="m-2">
                                <a href="/catalog/" className="footer__list-link">бренды</a>
                            </li>
                            <li className="m-2">
                                <a href="/contacts" className="footer__list-link">контакты</a>
                            </li>
                        </ul>
                    </nav>
                    <nav className="footer__menu">
                        <ul className="footer__list">
                            <li className="m-2">
                                <a href="https://wa.me/79213103810" className="footer__list-link">+7 (921) 310-38-10 </a>
                            </li>
                            <li className="m-2">
                                <a className="footer__list-link">office@w-part.ru</a>
                            </li>
                        </ul>
                    </nav>
                </div>
            </div>
        </footer> */}