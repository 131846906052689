import {useState} from 'react';
import Modal from '../components/window/Modal'
import { Document } from './Document';

const About = () => {
    const [open, setOpen] = useState(false)
    return (
        <section className="container mx-auto py-4">
            {open && <Modal setOpen={setOpen}>
                <a className='text-lg underline mr-44 text-right' href='/baltyre_doc.docx'>скачать файл</a>
            <Document />
            </Modal>}
            <div className="p-2 lg:flex">
                <img className="w-[600px] h-[400px] p-2" src="about.png" alt="about"></img>
                <div className="ps-6">
                    <p className='flex flex-col xl:flex-row xl:items-end justify-between mb-4 indent-8'>
                        <span className='text-3xl font-bold text-bold'>Балтайр в России</span>
                        <span className='underline text-lg cursor-pointer' onClick={()=>setOpen(true)}>карточка компании</span>
                        
                    </p>
                    <p className='text-xl mb-2 indent-8'>
                        <span onClick={()=>setOpen(true)} className='underline cursor-pointer'>ООО "Балтайр СПб"</span> является частью большой европейской BALTYRE GROUP. Учитывая более чем 20-летнюю историю работы в рынке шин в Европе, мы смогли быстро и качественно войти на российский рынок и быть полезными для клиентов в самых разных отраслях: от крестьянского хозяйства до крупнейших агропредприятий, от владельца грузового самосвала до автопарка с более чем 1000 единиц техники.
                        <p className='mb-2 indent-8'>Наши филиалы и партнерские склады расположены в крупнейших городах и регионах, что позволяет нам качественно обеспечивать бесперебойные поставки продукции нашим клиентам:
                        Санкт-Петербург, Москва, Краснодар, Волгоград, Ростов-на-Дону, Екатеринбург и многие другие.</p>
                        <p className='mb-2 indent-8'>Для наших партнеров мы предлагаем:
                        Индустриальные, Сельскохозяйственные, Грузовые шины известных мировых брендов
                        Европейский опыт использования шин
                        Профессиональный и качественный диалог с нашими клиентами
                        </p>                        
                    </p>
                </div>
            </div>
        </section>
    );
}

export default About;