import React, { useEffect, useState } from 'react';
import { useNavigate, Link, useLoaderData } from 'react-router-dom';
import { formatRub } from '../../utils';
import { TableCell, Table, TableRow, Thead, Tbody } from '../../components/table/Table';
import Tabs from '../../components/button/Tabs';
import SecondaryButton from '../../components/button/SecondaryButton';
import clientCartStore from '../../store/apiStore/ClientCartStore';
import { observer } from 'mobx-react-lite';
import EmptyTable from '../../components/EmptyTable'

const CartRow = ({ item }) => {
	return (
		<TableRow>
			<TableCell>
				<p>{item.brand_name}</p>
				<p>{item.article}</p>
			</TableCell>
			<TableCell>

			</TableCell>
			<TableCell>{item.title}</TableCell>
			<TableCell>
				{item.quantity}
			</TableCell>
			<TableCell>{item.location}</TableCell>
			<TableCell>{formatRub(item.price)}</TableCell>
			<TableCell>
				{formatRub(item.price * item.quantity)}
			</TableCell>

		</TableRow>
	)
}

export const CartUser = observer(() => {
	const client_id = useLoaderData()

	const { items, preItems, totalItems, totalPreItems, loading,
		fetchGetCart, fetchGetPreCart, cleanOrder, cleanPreOrder } = clientCartStore

	useEffect(() => {
		fetchGetPreCart(client_id)
		fetchGetCart(client_id)
	}, [])

	const navigate = useNavigate();
	const [tab, setTab] = useState(0)

	

	const handleBack = () => {
		cleanOrder()
		cleanPreOrder()
		navigate(-1)
	}

	return (
		<section class="cart">
			<div class="container mx-auto w-full py-6">
				<Tabs tab={tab} setTab={setTab} tabs={['Заказ', 'Предзаказ']} />
				<Table>
					<Thead>
						<TableCell>артикул</TableCell>
						<TableCell></TableCell>
						<TableCell>название</TableCell>
						<TableCell>кол-во</TableCell>
						<TableCell>склад</TableCell>
						<TableCell>цена</TableCell>
						<TableCell>сумма</TableCell>
					</Thead>
					{loading && <EmptyTable row={3} col={7}/>}
					{tab == 0 && items.map(item => <CartRow item={item} />)}
					{tab == 1 &&  preItems.map(item_ => <CartRow item={item_} />)}
				</Table>
				{tab == 0 &&
					<div class="my-5">
						<p class="text-2xl font-medium">Сумма: {formatRub(totalItems)}</p>
					</div>}
				{tab == 1 &&
					<div class="my-5">
						<p class="text-2xl font-medium">Сумма: {formatRub(totalPreItems)}</p>
					</div>}

					<SecondaryButton text={'назад к клиентам'} onClick={()=>handleBack() }/>
			</div>
			
		</section>
	)

})
