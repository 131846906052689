import React, { useEffect, useState } from 'react';
import Filters from '../components/Filters'
import Pagination from '../components/Pagination';
import { useMatches, useSearchParams } from 'react-router-dom';
import { findFirstMatch, formatRub, findAllMatches } from '../utils';
import Search from '../components/Search';
import Breadcrumbs from '../components/Breadcrumbs';
import { Table, TableRow, TableCell, Thead, Tbody } from '../components/table/Table';
import { Modal, ModalBlock, ModalBlockElement, ModalChangeElement } from '../components/window/Modal';
import ImageCard from '../components/image/ImageCard';
import MainButton from '../components/button/MainButton';
import { AdditionalAttributes } from '../components/Detail';
import useStore from '../store/RootStoreContext';
import marketStore from '../store/apiStore/MarketStore';
import { IntegerFieldTest } from '../components/input/IntegerField';
import { observer } from 'mobx-react-lite';
import galleryStore from '../store/apiStore/GalleryStore';
import EmptyTable from '../components/EmptyTable';


const CartBlock = observer(({ storage }) => {
    const { cartStore } = useStore()

    const orderItem = cartStore.items.find(
        obj => obj.item_id == storage.item_id && obj.storage_id == storage.storage_id) ||
        { ...storage, ['maxValue']: storage.quantity, ['quantity']: 0 }

    return (
        <IntegerFieldTest
            label={storage.storage_name}
            maxValue={storage.quantity}
            quantity={orderItem.quantity}
            increment={() => cartStore.increment(orderItem)}
            decriment={() => cartStore.decrement(orderItem)}
            onChange={(e) => cartStore.updateItemQuantity(orderItem, parseInt(e.target.value))} />
    )

})

const StoreModal = observer(() => {

    const [open, setOpen] = useState(false)

    const { images } = galleryStore
    const { item, cleanData } = marketStore

    return (
        <Modal setOpen={cleanData}>
            <div class="flex gap-8 border-b pb-4 pe-8">
                <ImageCard gallery={findAllMatches(images, item.pattern)} />
                <div class="flex-col">
                    <h2 className="font-medium text-2xl">
                        {item.title}
                    </h2>
                    <div className='flex gap-4 justify-between py-6 '>
                        <ModalBlock>
                            <ModalBlockElement name={'артикул'} value={item.article} />
                            <ModalBlockElement name={'бренд'} value={item.brand_name} />
                            <ModalBlockElement name={'тип'} value={item.type_name} />
                        </ModalBlock>
                        <ModalBlock>
                            {item.storages.map(storage => (
                                <ModalBlockElement name={storage.storage_name} value={storage.quantity > 10 ? '>10' : storage.quantity} />
                            ))
                            }
                        </ModalBlock>
                    </div>
                    <div className='flex'>
                        <div className='w-1/2 relative'>
                            <MainButton text={'в корзину'} onClick={() => setOpen(!open)} />
                            {open && <div className='flex flex-col gap-2 bg-white shadow-md p-4 border absolute'>
                                {item.storages.map(storage => <CartBlock storage={storage} />)}

                            </div>}
                        </div>
                        <p class="font-medium text-3xl">{formatRub(item.price)}</p>
                    </div>
                </div>
            </div>
            <AdditionalAttributes info={item.ItemInfo} pattern={item.pattern} />
        </Modal>
    )
})


const AddToCartStore = observer(({ storages, item_id }) => {
    const [hover, setHover] = useState(false)
    const { cartStore } = useStore()
    return (
        <div className="relative gap-1">
            <div className={`absolute border shadow-md z-50 bg-white right-0 ${hover ? 'show' : 'hidden'}`} onMouseLeave={() => setHover(false)}>
                <div className='flex flex-col p-4 gap-4 text-start'>
                    {storages.map(storage => <CartBlock storage={storage} />)}
                </div>
            </div>

            <button className="flex gap-2 pe-4" onMouseEnter={() => setHover(true)}>
                <span className='border-b text-lg text-center bg-transparent'>
                    {cartStore.items.reduce((prev, curr) => curr.item_id == item_id ? prev += curr.quantity : prev += 0, 0)}

                </span>
                <img src="/images/profile/icon-cart.svg" className={`h-8 w-8`} alt="icon-cart" />
            </button>
        </div>
    );
})

const Store = observer(() => {
    const [params, setParams] = useSearchParams()

    const { items, pages, types, brands, storages, isLoading, fetchMarketFromAPI, fetchFilterFromAPI } = marketStore
    const { images, fetchGalleryFromAPI } = galleryStore
    const { item, fetchItemFromAPI } = marketStore

    useEffect(() => {
        fetchMarketFromAPI()
        fetchFilterFromAPI()
        fetchGalleryFromAPI()
    }, [])

    useEffect(() => {
        fetchMarketFromAPI(params)
    }, [params])

    const handleOpenItem = async (event, item_id) => {
        const target = event.target;
        if (target.tagName === 'TD' && target.cellIndex !== target.parentElement.cells.length - 1) {
            fetchItemFromAPI(item_id)
        }
    }

    return (
        <>
            {item && <StoreModal />}
            < Search />
            <Breadcrumbs />
            <section class="catalog">
                <div class="container mx-auto">
                    <div class="flex gap-10">
                        <Filters brands={brands} types={types} storages={storages} />
                        <Table
                            Pagination={<Pagination total={pages} />} >
                            <Thead>
                                <TableCell>артикул</TableCell>
                                <TableCell className={'w-16'}></TableCell>
                                <TableCell>название</TableCell>
                                <TableCell>тип</TableCell>
                                <TableCell className={'w-20'}>кол-во</TableCell>
                                <TableCell>цена</TableCell>
                                <TableCell></TableCell>
                            </Thead>
                            <Tbody>

                                {marketStore.isLoading ?
                                    <EmptyTable row={25} col={6} /> :
                                    items.map(item => (
                                        <TableRow onClick={(e) => handleOpenItem(e, item.id, item.price_opt)}>
                                            <TableCell>
                                                <p>{item.brand_name}</p>
                                                <p>{item.article}</p>
                                            </TableCell>
                                            <TableCell>
                                                <img class="h-16 w-16" src={findFirstMatch(images, item.pattern)?.image_min || '/default_tire.svg'} alt="" />
                                            </TableCell>
                                            <TableCell>{item.title}</TableCell>
                                            <TableCell>{item.type_name}</TableCell>
                                            <TableCell>
                                                {item.storages.reduce((prev, curr) => prev += curr.quantity, 0) > 10 ?
                                                    '>10' :
                                                    item.storages.reduce((prev, curr) => prev += curr.quantity, 0)}
                                            </TableCell>
                                            <TableCell>{formatRub(item.price)}</TableCell>
                                            <TableCell>
                                                <AddToCartStore storages={item.storages} item_id={item.id} />
                                            </TableCell>
                                        </TableRow>
                                    ))}
                            </Tbody>
                        </Table>
                    </div>
                </div>
            </section>
        </>
    );
})


export default Store;
