export const Checkbox = ({name, onChange, checked, classSize }) => (
    <input className={`${classSize} w-4 h-4 cursor-pointer appearance-none border bg-clip-content p-[3px] checked:bg-gray-800 checked:border-gray-700`}
        name={name}
        onChange={onChange}
        checked={checked}
        type="checkbox" />
)


const CheckboxInput = ({ name, title, onChange, checked, className }) => (
    <label className="flex gap-2 items-center text-lg text-gray-600 cursor-pointer">
        <Checkbox name={name} onChange={onChange} checked={checked}/>
        <span class={className}>{title}</span>
    </label>
)
export default CheckboxInput