import { makeAutoObservable } from 'mobx';
import { AuthApi } from '../../api';

export class ClientStore {
    clients = []
    notAuthClients = []
    managers = []

    constructor() {
        makeAutoObservable(this);
    }

    setManagers = (data) => {
        this.managers = data
    }

    setClients = (data) => {
        this.clients = data.filter(obj => obj.is_active == true)
        this.notAuthClients = data.filter(obj => obj.is_active == false)
    }

    fetchGetManagers = async () => {
        try {
            const response = await AuthApi.managers()
            this.setManagers(response.data)
        } catch {
            console.error('error get managers')
        }
    }

    changeManager = async (user, manager) => {
        const newUser = { ...user, ['manager_id']: manager }
        try{
            const response = await AuthApi.changeManager(newUser)

        }catch{
            console.log('error change manager')
        }
    }

    fetchGetClients = async (search = null, page = null) => {
        try {
            const response = await AuthApi.clients(page, search)
            this.setClients(response.data)
        } catch {
            console.log('error')
        }
    }

    activateUser = async (user, manager_id = null, staff = false) => {
        const newUser = { ...user, ['manager_id']: manager_id, ['is_staff']: staff, ['is_active']: true }
        try{
            const response = await AuthApi.activate(newUser)
            this.notAuthClients = [...this.notAuthClients].filter(user_ => user_.email != user.email)
            this.clients.push({...user, ['manager_id']: manager_id})

        }catch{
            console.error('error activate user')
        }

    }

    deleteUser = async (email) => {
        try{
            const response = await AuthApi.delete(email)
            this.notAuthClients = [...this.notAuthClients].filter(user => user.email != email)
        }catch{
            console.error('error')
        }
    }

    searchUser = (search) => {
        this.fetchGetClients(search)
    }

}

const clientStore = new ClientStore()
export default clientStore