import { makeAutoObservable } from 'mobx';
import { ContainerApi } from '../../api';

export class ContainerStore {
    items = []
    item = null
    pages = 0
    brands = []
    types = []
    isLoading = false

    constructor() {
        makeAutoObservable(this);
    }

    setLoading = (bool) => {
        this.isLoading = bool
    }

    setItems = ({items, total_page}) => {
        this.items = items;
        this.pages = total_page
    }

    setFilters = ({types, brands}) => {
        this.brands = brands
        this.types = types
    }

    cleanData = () => {
        this.item = null
    }

    setItem = (data) => {
        this.item = data
    }

    fetchItemFromAPI = async (item_id) => {
        try {
            const response = await ContainerApi.getItem(item_id)
            this.setItem(response.data)
        } catch (error) {
            console.error('Error fetching cart:', error);
        }
    }

    fetchContainerFromAPI = async (container_id, params) => {
        try {
            this.setLoading(true)
            const response = await ContainerApi.getItems(container_id, params);
            this.setItems(response.data)
        } catch (error) {
            console.error('Error fetching cart:', error);
        }
        this.setLoading(false)
    }
    fetchFilterFromAPI = async () => {
        try {
            const response = await ContainerApi.getFilters();
            this.setFilters(response.data)
            
        } catch (error) {
            // Обработка ошибок, если необходимо
            console.error("Error fetching data:", error);
        }
    }
    
}

const containerStore = new ContainerStore();
export default containerStore