import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";



const Pagination = ({total}) => {

    const [searchParams, setSearchParams] = useSearchParams()  
    const [page, setPage] = useState(parseInt(searchParams.get('page')) || 1)  

    const handleGetPage = (pageNumber) => {
        searchParams.delete('page')
        searchParams.append('page', pageNumber)
        setSearchParams(searchParams)
        setPage(pageNumber)
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }
    useEffect(() => {
        setPage(parseInt(searchParams.get('page')) || 1)
    }, [searchParams])

    return (
        
        <div className="flex mx-auto text-lg my-8">
            {page > 1 ? (
                <a className="bg-transparent border-0 text-my-yellow cursor-pointer outline-none" onClick={() => handleGetPage(page - 1)}>
                &lt;
                </a>
            ) : (
                <a className="bg-transparent border-0 text-gray-200 cursor-pointer outline-none">&lt;</a>
            )}

            <ul className="flex gap-2 px-4">
                {page - 2 >= 1 && (
                    <li className="cursor-pointer px-2 hover:bg-neutral-100" onClick={() => handleGetPage(page - 2)}>
                        <a>{page - 2}</a>
                    </li>
                )}
                {page - 1 >= 1 && (
                    <li className="cursor-pointer px-2 hover:bg-neutral-100" onClick={() => handleGetPage(page - 1)}>
                        <a >{page - 1}</a>
                    </li>
                )}
                <li className="cursor-pointer px-2 bg-neutral-100" onClick={() => handleGetPage(page)}>
                    <a >{page}</a>
                </li>
                {page + 1 <= total && (
                    <li className="cursor-pointer px-2 hover:bg-neutral-100" onClick={() => handleGetPage(page + 1)}>
                        <a >{page + 1}</a>
                    </li>
                )}
                {page + 2 <= total && (
                    <li className="cursor-pointer px-2 hover:bg-neutral-100" onClick={() => handleGetPage(page + 2)}>
                        <a >{page + 2}</a>
                    </li>
                )}
            </ul>

            {page < total ? (
                <a className="bg-transparent border-0 text-my-yellow cursor-pointer outline-none" onClick={() => handleGetPage(page + 1)}>
                    {'>'}
                </a>
            ) : (
                <a className="bg-transparent border-0 text-gray-200 cursor-pointer outline-none">{'>'}</a>
            )}
        </div>
    );
};

export default Pagination