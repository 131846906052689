import React, { useEffect, useState } from 'react';
import { Form } from 'react-router-dom'
import Tabs from '../../components/button/Tabs'
import MainButton from '../../components/button/MainButton'
import { observer } from 'mobx-react-lite';
import settingsStore from '../../store/apiStore/SettingsStore';

const SettingsInput = ({ label, name, value, onChange }) => {
    return (
        <label className='flex gap-6 items-center'>
            <span className='w-[248px]'>{label}</span>
            <input className='border-2 p-1 px-3 text-xl' name={name} value={value} onChange={onChange} />
        </label>
    )
}


const Settings = observer(() => {

    const { catalog, mail, setCatalog, setMail, fetchGetSettings, fetchUpdateSettings } = settingsStore

    const [tab, setTab] = useState(0)

    useEffect(() => {
        fetchGetSettings()
    }, [fetchGetSettings])

    const handleSubmit = (event, name) => {

        event.preventDefault()
        fetchUpdateSettings(name)
    }

    return (
        <section className='settings'>
            <div className='container mx-auto'>
                <div className='flex flex-col justify-center mt-8 mb-8 '>
                    <Tabs tab={tab} setTab={setTab} tabs={['настройки каталога', 'почта']} />
                    {tab == 0 && (
                        <Form onSubmit={(e)=>handleSubmit(e, 'catalog')}
                            className="justify-around my-8 pb-12 relative flex flex-col">
                            <div className='flex'>
                                <div className='p-4 gap-4 flex flex-col'>
                                    <SettingsInput
                                        label='Доставка до границы (евро)'
                                        name='delivery_out'
                                        value={catalog.get('delivery_out') || ''}
                                        onChange={(e) => setCatalog('delivery_out', e.target.value)} />
                                    <SettingsInput
                                        label='Доставка после границы (руб)'
                                        name='delivery_in'
                                        value={catalog.get('delivery_in')}
                                        onChange={(e) => setCatalog('delivery_in', e.target.value)} />
                                    <SettingsInput
                                        label='Томоженное оформление (руб)'
                                        name='clearance'
                                        value={catalog.get('clearance')}
                                        onChange={(e) => setCatalog('clearance', e.target.value)} />
                                    <SettingsInput
                                        label='Утиль сбор (руб)'
                                        name='scrap'
                                        value={catalog.get('scrap')}
                                        onChange={(e) => setCatalog('scrap', e.target.value)} />
                                    <SettingsInput
                                        label='ПРР (руб)'
                                        name='prr'
                                        value={catalog.get('prr')}
                                        onChange={(e) => setCatalog('prr', e.target.value)} />
                                    <SettingsInput
                                        label='Остальные расходы (руб)'
                                        name='another'
                                        value={catalog.get('another')}
                                        onChange={(e) => setCatalog('another', e.target.value)} />
                                </div>
                                <div className='p-4 gap-4 flex flex-col'>
                                    <SettingsInput
                                        label='Пошлина %'
                                        name='duty'
                                        value={catalog.get("duty")}
                                        onChange={(e) => setCatalog('duty', e.target.value)} />
                                    <SettingsInput
                                        label='Антидемпинговая пошлина %'
                                        name='anti_demping'
                                        value={catalog.get('anti_demping')}
                                        onChange={(e) => setCatalog('anti_demping', e.target.value)} />
                                    <SettingsInput
                                        label='Наценка %'
                                        name='markup'
                                        value={catalog.get('markup')}
                                        onChange={(e) => setCatalog('markup', e.target.value)} />

                                </div>
                            </div>
                            <div className='mx-auto pt-4'>
                                <MainButton text={'сохранить и обновить'} type='submit' />
                            </div>

                        </Form>
                    )}
                    {tab == 1 && (
                        <Form onSubmit={(event) => handleSubmit(event, 'mail')} className={`justify-around my-8 pb-12 relative flex flex-col`}>
                            <div className='flex'>
                                <div className='p-4 gap-4 flex flex-col'>
                                    <SettingsInput
                                        label={'Почта (для отправки)'}
                                        name={'email'}
                                        value={mail.get('email')}
                                        onChange={(e) => setMail('email', e.target.value)} />
                                    <SettingsInput
                                        label={'Пароль для приложений'}
                                        name={'password'}
                                        value={mail.get('password')}
                                        onChange={(e) => setMail('password', e.target.value)} />
                                    <SettingsInput
                                        label={'SMTP-сервер'}
                                        name={'server'}
                                        value={mail.get('server')}
                                        onChange={(e) => setMail('server', e.target.value)} />
                                    <SettingsInput
                                        label={'Порт SMTP'}
                                        name={'port'}
                                        value={mail.get('port')}
                                        onChange={(e) => setMail('port', e.target.value)} />
                                    <SettingsInput
                                        label={'SSL'}
                                        name={'ssl'}
                                        value={mail.get('ssl') || 'False'}
                                        onChange={(e) => setMail('ssl', e.target.checked ? 'True' : 'False')} />
                                    <SettingsInput
                                        label={'TLS'}
                                        name={'tls'}
                                        value={mail.get('tls') || 'False'}
                                        onChange={(e) => setMail('tls', e.target.checked ? 'True' : 'False')} />
                                </div>
                                <div className='p-4 gap-4 flex flex-col'>
                                    <SettingsInput
                                        label={'Почта для заказов'}
                                        name={'smtp_orders'}
                                        value={mail.get('smtp_orders')}
                                        onChange={(e) => setMail('smtp_orders', e.target.value)} />

                                </div>
                            </div>

                            <div className='mx-auto'>
                                <MainButton text='сохранить и обновить' type='submit' />
                            </div>

                        </Form>
                    )}
                </div>
            </div>
        </section>
    );
});

export default Settings;