import React, { useEffect, useState } from 'react';
import { loader } from '../../utils';
import useStore from '../../store/RootStoreContext';
import { observer } from 'mobx-react-lite';
import galleryStore from '../../store/apiStore/GalleryStore';


const Gallery = observer(() => {

    const {userStore} = useStore()
    const {
        fetchGalleryFromAPI, removeImage, importZip,
        images, isLoading} = galleryStore
    
    useEffect(()=>{
        fetchGalleryFromAPI()
    }, [])

    return (
        <>
        {userStore.checkAdmin() && (
            <div className='container mx-auto'>
                <label class="flex mt-4 items-center gap-1 justify-center w-[200px] cursor-pointer bg-gray-500 hover:bg-gray-700 text-white font-bold py-1 px-3">
                    <p className='flex'>{isLoading ?  loader : 'добавить архив'}</p>
                    <input type='file' hidden onChange={(e)=>importZip(e.target.files[0])}></input>
                    <img src={process.env.PUBLIC_URL + '/tooltag-add.svg'} className='w-4 h-3 my-auto ' />
                </label>
            </div>
            )}

            <div className='container mx-auto'>
                <div class="row">
                    {images.map(img => (
                        <div className='image_container'>
                            <img src={img.image} alt="wheel" className="image" />
                            <div className="overlay">{img.title}</div>
                            {userStore.checkAdmin() && (
                                <div className='overlay_delete' onClick={()=>removeImage(img.id)}>&#128938;</div>
                            )}
                        </div>
                    ))}

                </div>
            </div>
        </>
    );
});

export default Gallery;