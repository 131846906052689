import { Link } from 'react-router-dom'
import Search from '../components/Search'
import { ordersStore } from '../store/apiStore/OrderStore'
import { observer } from 'mobx-react-lite'
import { useEffect, useState } from 'react'
import useStore from '../store/RootStoreContext'
import Modal from '../components/window/Modal'
import { Document } from './Document'

const ProgressBar = observer(({ title, step, orderId }) => {
    const progress = step == 1 ? '0px' : `${(step - 2) * 25}%`
    const steps = ['принят', 'в работе', 'выставлен счет', 'оплачен', 'выполнен']
    return (
        <div className='flex items-center py-8'>
            <span className='font-medium w-44'>{title}</span>
            <div class="w-full bg-gray-200 h-2.5 relative">
                {steps.map((st, index) => {
                    const stepClass = index == 0 ? '-left-[2%]' : `${index * 25 - 2}%`
                    return (
                        <div className={`absolute w-8 text-nowrap -top-9 flex flex-col items-center`} style={{left: stepClass}}>
                            <span className=''>{st}</span>
                            <div className={`w-8 h-8 ${step - 2 >= index ? "bg-my-yellow" : "bg-gray-200"} rounded-full`}></div>
                        </div>
                    )
                })}
                <div class={`bg-my-yellow h-2.5`} style={{ width: progress }}></div>
            </div>
            <Link className='w-44 text-right' to={`/orders/${orderId}`}>
                <span className='underline'>подробнее</span>
            </Link>
        </div>
    )
})


const HomePage = observer(() => {
    const { fetchGetOrders, notDoneOrders } = ordersStore
    const { userStore } = useStore()
    const { user } = userStore
    const [open, setOpen] = useState()

    useEffect(() => {
        fetchGetOrders()
    }, [])
    return (
        <>
            {open && <Modal setOpen={setOpen}>
                <a className='text-lg underline mr-44 text-right' href='/baltyre_doc.docx'>скачать файл</a>
                <Document />
            </Modal>}
            <Search />
            <div className="container mx-auto">
                <div className='info py-8 flex justify-between'>
                    <div className=''>
                        <p className='font-medium text-2xl'>Грузополучатель</p>
                        <p className=''>{user.person?.fio}</p>
                        <p className=''>{user.company ? user.company.company_name + ', ': ''}{user.person?.city}</p>
                    </div>
                    <div className='text-right'>
                        <p className='font-medium text-xl'>ООО «БАЛТАЙР СПБ», 7810901609</p>
                        <p className=''>Санкт-Петербург, наб. Обводного канала, д. 92, литер А, офис 215</p>
                        <p className=' underline cursor-pointer' onClick={() => setOpen(true)}>карточка компании</p>
                    </div>
                </div>
                <div>
                    {notDoneOrders.slice(0, 3).map(order => <ProgressBar title={'Заказ #' + order.id} step={order.step} orderId={order.id} />)}
                    {notDoneOrders.length > 3 &&
                        <div className='w-full text-center mb-4'>
                            <Link to={'/orders'}><span className='cursor-pointer p-2 text-lg underline'>все заказы</span></Link>
                        </div>
                    }
                </div>
                {/* <div className='w-full text-center'>
                    <h3 className='font-medium text-2xl'>ШИНЫ</h3>
                </div> */}
                <div className='flex  justify-around mt-16 mb-8'>
                    <Link to={'/store?type=3'} className='hover:shadow-md p-4 group'>
                        <div className='w-48 items-center text-center'>
                            <img src='/indystry.png' className='h-48 mx-auto object-contain scale-110 group-hover:scale-125 group-hover:duration-700' />
                            <span className='font-medium text-2xl'>Индустриальные</span>
                        </div>
                    </Link>
                    <Link to={'/store?type=5'} className='hover:shadow-md p-4 group'>
                        <div className='w-48 items-center text-center'>
                            <img src='/agro.png' className='h-48 mx-auto scale-110 group-hover:scale-125 group-hover:duration-700' />
                            <span className='font-medium text-2xl'>Агрошины</span>
                        </div>
                    </Link>
                    <Link to={'/store?type=8'} className='hover:shadow-md p-4 group'>
                        <div className='w-48 items-center text-center'>
                            <img src='/legkogruz.png' className='h-48 mx-auto group-hover:scale-110 group-hover:duration-700' />
                            <span className='font-medium text-2xl z-10 relative'>Легкогрузовые</span>
                        </div>
                    </Link>
                    <Link to={'/store?type=4'} className='hover:shadow-md p-4 group'>
                        <div className='w-48 items-center text-center'>
                            <img src='/gruz.png' className='h-48 mx-auto group-hover:scale-110 group-hover:duration-700' />
                            <span className='font-medium text-2xl z-10 relative'>Грузовые</span>
                        </div>
                    </Link>

                </div>
            </div>
            <div className='w-full text-center'>
                <h3 className='font-medium text-2xl bg-gray-200 bg-opacity-25 py-4 mb-8'>Новости</h3>
            </div>
            <div className='container mx-auto'>
                <div className='mb-8'>
                    <div className='p-8 hover:shadow-md hover:bg-gray-100 hover:bg-opacity-25 flex gap-10 w-full'>
                        <img src='/new.jpg' className='w-1/2' />
                        <div>
                            <h2 className='font-medium text-xl p-2 select-none'>Новый B2B Портал Baltyre Упрощает Торговлю Шинами</h2>
                            <span className='text-gray-400 p-2 select-none'>02.04.2024</span>
                            <div className='text-lg p-2 select-none'>
                                Мы с гордостью объявляем о запуске нашего нового B2B портала - Baltyre!
                                Baltyre создан для упрощения и оптимизации процесса торговли шинами,
                                предоставляя эффективную платформу для взаимодействия между поставщиками и покупателями.
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>

    )
})

export default HomePage;
