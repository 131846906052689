import { makeAutoObservable } from 'mobx';
import { GallaryApi } from '../../api';


export class GallaryStore {
    images = []
    isLoading = false

    constructor() {
        makeAutoObservable(this);
    }

    setImages = (data) => {
        this.images = data
    }

    pushImages = (data) => {
        this.images.push(...data)
    }

    setLoading = (bool) => {
        this.isLoading = bool
    }

    delete = (id) => {
        this.images = this.images.filter(item => item.id !== id);
    }

    removeImage = async (id) => {
        try{
            const response = await GallaryApi.delete(id)
            const data = response.data
            if (data){
                this.delete(id)
            }  
        }catch{
            console.error('gallery remove image')
        }      
    }

    importZip = async (file) => {
        this.setLoading(true)
        try{
            const response = await GallaryApi.import(file)
            const data = await response.data
            this.pushImages(data)
        }catch{
            console.error('error import zip')
        }
        this.setLoading(false)
    }

    fetchGalleryFromAPI = async () => {
        try {
            const response = await GallaryApi.getList();
            this.setImages(response.data)
        } catch (error) {
            // Обработка ошибок, если необходимо
            console.error("Error fetching data:", error);
        }
    };

}


const galleryStore = new GallaryStore()

export default galleryStore;