const TextInput = ({name, onChange, onKeyUp, autocomplete, placeholder, value, type='text', required=false}) => {
    return (
        <input className="w-full border outline-none py-3 px-6 text-xl focus:border-gray-600" 
            type={type} 
            autocomplete={autocomplete} 
            placeholder={placeholder} 
            name={name} 
            onChange={onChange} 
            required={required}
            onKeyUp={onKeyUp}
            value={value}/>
    )
}

export default TextInput;