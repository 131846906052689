import { useEffect } from "react";
import { RouterProvider, createBrowserRouter, Link, useRouteError } from "react-router-dom";

import { HomePageRoute, ProtectedRoute, ManagerRoute, AdminRoute } from "./ProtectedRoute";
import Root from './pages/Root';
import Containers from './pages/Containers';
import Store from './pages/Store';
import { Catalog } from './pages/manager/Catalog';
import ErrorPage from './pages/ErrorPage';
import { Cart } from "./pages/Cart";
import BaseCart from "./pages/BaseCart";
import Clients from "./pages/manager/Clients";
import { CartUser } from "./pages/manager/CartUser";
import Gallery from "./pages/admin/Gallery";
import { GallaryApi} from "./api";
import Settings from "./pages/admin/Settings";
import Orders from "./pages/Orders";
import OrderDetails from "./pages/OrderDetails";
import Profile from "./pages/Profile";
import { observer } from "mobx-react-lite";
import useStore from "./store/RootStoreContext";
import HomePage from "./pages/HomePage";
import About from "./pages/Home";


function ErrorBoundary() {
  let error = useRouteError();
  console.error(error);
  return <div>Dang!</div>;
}

const Routes = observer(() => {
  const { userStore } = useStore();

  // Define public routes accessible to all users
  const routesForPublic = [
    {
      path: "/",
      element: <Root />,
      errorElement: <ErrorPage />,
      handle: {
        crumb: () => <Link to="/">главная</Link>,
      },
      children: [
        {
          index: true,
          element: <HomePageRoute />,

        },
      ]
    }
  ];

  // Define routes accessible only to authenticated users
  const routesForAuthenticatedOnly = [
    {
      path: "/",
      element: <ProtectedRoute />,
      errorElement: <ErrorPage />,
      handle: {
        crumb: () => <Link to="/">главная</Link>,
      },
      children: [
        {
          index: true,
          element: <HomePage />,
        },
        {
          path: '/profile',
          element: <Profile />
        },
        {
          path: '/cart',
          element: <BaseCart />,
          handle: {
            crumb: () => <Link to='/cart'>корзина</Link>
          },
          children: [
            {
              index: true,
              element: <Cart />,
            }
          ]
        },
        {
          path: '/orders',
          element: <Orders />,
          crumb: () => <Link to='/orders'>заказы</Link>
        },
        {
          path: '/orders/:orderId',
          element: <OrderDetails />,
          loader: ({ params }) => params.orderId
        },

        {
          path: '/containers',
          element: <Containers />,
          handle: {
            crumb: () => <Link to="/containers">контейнеры</Link>,
          },
        },
        {
          path: '/containers/:container_id',
          element: <Containers />,
          loader: ({ request, params }) => params.container_id,
          handle: {
            crumb: () => {
              return <Link></Link>
            },
          },

        },
        {
          path: '/store',
          element: <Store />,
          errorElement: <ErrorBoundary />,
          handle: {
            crumb: () => <Link to="/store">каталог</Link>,
          },
        },
      ]
    },
  ];

  const routesForManager = [
    {
      path: "/",
      element: <ManagerRoute />,
      errorElement: <ErrorPage />,
      handle: {
        crumb: () => <Link to="/">главная</Link>,
      },
      children: [
        {
          path: '/gallery',
          element: <Gallery />
        },
        {
          path: '/catalog',
          element: <Catalog />,
          handle: {
            crumb: () => <Link to="/catalog">магазин</Link>,
          }
        },
        {
          path: '/clients',
          element: <Clients />,
          handle: {
            crumb: () => <Link to="/notauth">пользователи</Link>,
          },
        },
        {
          path: `/clients/:client_id/cart`,
          element: <CartUser />,
          loader: ({params}) => params.client_id
        },
      ]
    }
  ];

  const routesForAdmin = [
    {
      path: "/",
      element: <AdminRoute />,
      errorElement: <ErrorPage />,
      handle: {
        crumb: () => <Link to="/">главная</Link>,
      },
      children: [
        {
          path: '/settings',
          element: <Settings />
        },
      ]
    }
  ];

  // Combine and conditionally include routes based on authentication status
  const router = createBrowserRouter([
    ...routesForPublic,
    ...routesForAuthenticatedOnly,
    ...routesForManager,
    ...routesForAdmin
  ]);


  useEffect(()=>{
    userStore.fetchGetUser()
  }, [])

  if (userStore.loading) {
    return <div>Loading...</div>;
  }

  return <RouterProvider router={router} />;

});

export default Routes;