import { useState } from "react";

const ImageCard = ({ gallery }) => {
    const [images, setImages] = useState(gallery)
    const handleSortImages = (img) => {
        const newImages = images.filter(obj => obj != img)
        setImages([img, ...newImages])

    }
    return (
        <div class="w-2/5 h-full flex grid-cols-2 gap-4 overflow-hidden relative">
            <img class="w-full max-h-96 max-w-96 h-full object-contain hover:scale-125 transition-all duration-500 cursor-pointer" src={images[0]?.image || '/default_tire.svg'} alt="" />
            {images.length > 1 && (
                <div class="flex absolute w-24 right-0 flex-col h-full gap-4 overflow-y-scroll">
                    {images?.slice(1)?.map(img => (
                        <div className='cursor-pointer' onClick={()=>handleSortImages(img)}>
                            <img class="max-w-20" src={img?.image} alt="/" />
                        </div>
                    ))}
                </div>
            )}

        </div>)
}

export default ImageCard;