import React, { useEffect, useState } from 'react';
import { Link } from "react-router-dom";
import { useModal } from '../provider/ModalProvider';
import AuthModal from './AuthModal';
import LinkNavigation from './navigation/LinkNavigation';
import Dropdown from './window/Dropdown';
import Modal from './window/Modal';
import { Badge } from './information/Badge';
import useStore from '../store/RootStoreContext'
import { observer } from 'mobx-react-lite';

const Header = observer(() => {
    const {message, setMessage } = useModal()
    const {cartStore, preCartStore, userStore} = useStore()

    const { user, person, manager } = userStore

    useEffect(()=>{
        if (userStore.checkActive()){
            cartStore.fetchCartFromAPI()
            preCartStore.fetchPreCartFromAPI()
        }  
    }, [])

    const handleLogout = () => {
        userStore.logout()
        cartStore.cleanData()
        preCartStore.cleanData()
    }

    const [dropdown, setDropdown] = useState(false)

    return (
        <div>
            {userStore.modal && <AuthModal />}
            {message && (
                <Modal setOpen={setMessage}>
                    <p class="mx-auto text-2xl">{message}</p>
                </Modal>

            )}
            <header className='z-100 bg-white relative'>
                <div className="container mx-auto py-4">
                    <div className="flex justify-between gap-2">
                        <Link to='' className="logo">
                            <img className="w-60" src={process.env.PUBLIC_URL + "/logo-baltyre.png"} alt="logo" /></Link>
                        <div className="header__city">
                        </div>
                        <div className="flex items-center gap-2 md:gap-8">
                            {userStore.checkActive() &&
                            <div className="hidden md:flex">
                                <p className="header__info-company">
                                    <p>{person?.company ? person.company?.company_name : person?.fio}</p>
                                    <p>{person?.city}</p>

                                </p>
                            </div>}
                            <Link to="/cart" className="header__buttons-cart relative">
                                <img className=' md:w-10 md:h-10 h-6 w-6' src="/images/cart.svg" alt="cart" />
                                {(cartStore.count+preCartStore.count) > 0 && <Badge text={(cartStore.count + preCartStore.count)} />}
                            </Link>
                            <div >
                                {userStore.checkActive() ? (
                                    <Dropdown className={'!w-80'} button={<img className='md:w-10 md:h-10 h-6 w-6' src="/images/account.svg" alt="account" />} >
                                        <p className="p-4 border-b">
                                            <p className=''>{person?.company ? person?.company?.company_name : person?.fio}, {person?.city}</p>
                                            <p>
                                                {user?.manager && (
                                                    <>
                                                        <span className='font-medium'>Менеджер:</span> {manager?.person?.fio}
                                                        <p>{manager?.email}</p>
                                                        <p>{manager?.person.phone_number}</p>
                                                    </>
                                                )}
                                            </p>
                                        </p>
                                        <Link className="text-gray-700 block px-4 py-2 text-sm hover:bg-gray-200" to="/profile">настройки аккаунта</Link>
                                        <Link className="text-gray-700 block px-4 py-2 text-sm hover:bg-gray-200" onClick={() => handleLogout()}>выйти</Link>
                                    </Dropdown >
                                ) :
                                    (
                                        <button type='button' className='outline-none bg-transparent decoration-transparent p-0 mt-2'
                                            onClick={() => userStore.openModal()}>
                                            <img className=' md:w-10 md:h-10 h-6 w-6' src="/images/account.svg" alt="account" />
                                        </button>
                                    )}
                            </div>
                            <div>
                                <img className='lg:hidden md:w-10 md:h-10 h-6 w-6 flex' onClick={() => setDropdown(!dropdown)} src="images/menu-mobile.svg" alt="menu" />
                            </div>
                        </div>
                    </div>

                </div>
                <div className={`bg-[#1a1a1a] right-0 sm:w-1/2 md:w-1/3 lg:w-full only:sm:md:top-20 absolute lg:relative lg:visible ${!dropdown && 'invisible lg:visible'}`}>
                    <div className="mx-auto h-full md:container">
                        <div className="navigation__inner">
                            <nav className='flex items-center align-center text-2xl font-medium justify-center lg:justify-between'>
                                <ul className="flex-col flex items-center lg:flex-row ">
                                    <LinkNavigation link={'/store'} title={'склад'} />
                                    <LinkNavigation link={'/containers'} title={'контейнеры'} />
                                    <LinkNavigation link={'/orders'} title={'заказы'} />
                                    {userStore.checkStaff() && 
                                        <>
                                        <LinkNavigation link={'/clients'} title={'клиенты'} />
                                            <LinkNavigation link={'/catalog'} title={'каталог'} />
                                            <LinkNavigation link={'/gallery'} title={'галерея'} />
                                        </>
                                    }
                                    {userStore.checkAdmin() && 
                                        <LinkNavigation link={'/settings'} title={'настройки'} />
                                    }
                                </ul>
                                <LinkNavigation className={'lg:text-2xl text-lg lg:flex hidden truncate'} link={'tel:+79213103810'} title={'+7 (812) 317-50-12'} />
                            </nav>
                        </div>
                    </div>
                </div>
            </header>
        </div>
    )
})

export default Header;