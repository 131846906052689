import { CatalogApi } from "../../api"
import { makeAutoObservable } from 'mobx';

export class CatalogStore {
    items = []
    item = null
    pages = 0
    types = []
    brands = []

    constructor() {
        makeAutoObservable(this);
    }

    setData = ({items, total_page}) => {
        this.items = items
        this.pages = total_page
    }

    setFilters = ({brands, types}) => {
        this.brands = brands
        this.types = types
    }

    cleanItem = () => {
        this.item = null
    }

    fetchGetCatalog = async (params) => {
        try{
            const response = await CatalogApi.getList(params)
            this.setData(response.data )
          }catch{
            console.log('error response')
          }
    }
    fetchGetFilters = async () => {
        try {
            const response = await CatalogApi.getFilters();
            this.setFilters(response.data)


        } catch (error) {
            // Обработка ошибок, если необходимо
            console.error("Error fetching data:", error);
        }
    }

    fetchGetItem = async (item_id) => {
        try{
            const response = await CatalogApi.get(item_id)
            this.item = { ...response.data, ['price']: response.data.price_rub } 
        }catch{

        }
    }
}

const catalogStore = new CatalogStore()
export default catalogStore