import { useState, useEffect } from "react";
import {  useNavigate } from 'react-router-dom';
import { formatRub } from '../utils';
import Tabs from "../components/button/Tabs";
import Table, { TableCell, TableRow, Thead } from "../components/table/Table";
import SelectInput from '../components/input/SelectInput'
import { observer } from "mobx-react-lite";
import useStore from "../store/RootStoreContext";
import { ordersStore } from "../store/apiStore/OrderStore";

const jsonTODate = (json) => {
    var d = new Date(json);
    return d.toLocaleDateString()
}

const Orders = observer(() => {
    const {
        fetchGetOrders, fetchGetStatus, cancelOrder, changeStatus,
        orders, preOrders, canceledOrders, statuses, waiting} = ordersStore
    const {userStore} = useStore()

    useEffect(()=>{
        fetchGetOrders()
        fetchGetStatus()
    }, [])

    const [tab, setTab] = useState(0)

    const navigate = useNavigate();

    const handleClick = (e, link) => {
        if (!e.target.className.includes('noClick')){
            navigate(link)
        }
    }

    const OrderRow = observer(({ order }) => {
        const [waiting, setWaiting] = useState(false)
        return (
            <TableRow onClick={(e)=>handleClick(e, `/orders/${order.id}`)} 
                className={waiting && 'animate-pulse bg-red-50 hover:bg-red-50 '}>
                <TableCell># {order.id}</TableCell>
                {userStore.checkStaff() && (
                    <>
                        <TableCell>{order.fio}</TableCell>
                        <TableCell>{order.phone_number}</TableCell>
                        <TableCell>{order.email}</TableCell>
                    </>
                )}
                <TableCell>{formatRub(order.sum_items)}</TableCell>
                <TableCell>{jsonTODate(order.update_date)}</TableCell>
                <TableCell className={'noClick'}>
                    {userStore.checkStaff() ? (
                        <SelectInput className={'noClick'} defaultValue={order.status_id} onChange={(e) => changeStatus(order, e.target.value)}>
                            {statuses?.map(st => (
                                <option className={'noClick'} value={st.id}>{st.status}</option>
                            ))}
                        </SelectInput>
                    ) :
                        order.status}

                </TableCell>
                <TableCell className={'noClick'}>
                    {order.step != -1 &&
                        <button className='noClick' onClick={() => cancelOrder(order, setWaiting)}>
                            <img className='noClick w-6 h-6 hover:scale-110' src='/images/profile/icon-return.svg'></img>
                        </button>}
                </TableCell>
            </TableRow>
        )
    })

    return (
        <section class="orders">
            <div class="container mx-auto">
                <div class="py-4">
                    <Tabs tabs={['заказы', 'предзаказы', 'отмены']} className={'!w-1/4'} tab={tab} setTab={setTab} />
                    <div class="py-2">
                        <Table>
                            <Thead>
                                <TableCell>заказ</TableCell>
                                {userStore.checkStaff() && (
                                    <>
                                        <TableCell>клиент</TableCell>
                                        <TableCell>телефон</TableCell>
                                        <TableCell>почта</TableCell>
                                    </>
                                )}
                                <TableCell>сумма</TableCell>
                                <TableCell>дата</TableCell>
                                <TableCell>статус</TableCell>
                                <TableCell>отмена</TableCell>
                            </Thead>
                            {tab == 0 && orders.map(order => <OrderRow order={order} />)}
                            {tab == 1 && preOrders.map(order => <OrderRow order={order} />)}
                            {tab == 2 && canceledOrders.map(order => <OrderRow order={order} />)}
                        </Table>
                    </div>
                </div>
            </div>
        </section>
    )
})

export default Orders;