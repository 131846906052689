import { useRef, useEffect } from "react";


export const findFirstMatch = (arr, pattern) => {
    const regex = new RegExp(pattern, "i"); // "i" для игнорирования регистра
    return arr.find(obj => regex.test(obj.title));
};

export const findAllMatches = (arr, pattern) => {
    const regex = new RegExp(pattern, "i"); // "i" для игнорирования регистра
    return arr.filter(obj => regex.test(obj.title));
  };

 export const formatRub = (amount) => {
    if (amount) {
        return amount.toLocaleString('ru-RU', {
            style: 'currency', currency: 'RUB', minimumFractionDigits: 0,
            maximumFractionDigits: 0,
        });
    }
    return amount
}

export const loader =(
    <div class='flex space-x-2 py-2 justify-center items-center dark:invert'>
        <div class='h-2 w-2 bg-white rounded-full animate-bounce [animation-delay:-0.3s]'></div>
        <div class='h-2 w-2 bg-white rounded-full animate-bounce [animation-delay:-0.15s]'></div>
        <div class='h-2 w-2 bg-white rounded-full animate-bounce'></div>
    </div>)

export function formatEuro(amount) {
    if (amount !== null && amount !== undefined) {
        return amount.toLocaleString('eu-EU', {
            style: 'currency',
            currency: 'EUR',
            minimumFractionDigits: 0,
            maximumFractionDigits: 2,
        }).replace('.', ' ');
    }
    return amount;
}

export const useOutsideClick = (callback) => {
    const ref = useRef();

    useEffect(() => {
        const handleClick = (event) => {
            if (ref.current && !ref.current.contains(event.target)) {
                callback();
            }
        };

        document.addEventListener('click', handleClick, true);

        return () => {
            document.removeEventListener('click', handleClick, true);
        };
    }, [ref]);

    return ref;
};


export function formatDate(date) {
    const currentDate = new Date(date);

    var dd = currentDate.getDate();
    if (dd < 10) dd = '0' + dd;
  
    var mm = currentDate.getMonth() + 1;
    if (mm < 10) mm = '0' + mm;
  
    var yy = currentDate.getFullYear() % 100;
    if (yy < 10) yy = '0' + yy;
  
    return dd + '.' + mm + '.' + yy;
  }