import React, { useState, useEffect } from 'react';
import { Form } from 'react-router-dom';
import { useAuth } from '../provider/AuthProvider';
import InputMask from 'react-input-mask';
import { useModal } from '../provider/ModalProvider';
import { AuthApi } from '../api';
import { loader } from '../utils';
import { Modal } from '../components/window/Modal'
import Tabs from '../components/button/Tabs'
import TextInput from './input/TextInput';
import MainButton from '../components/button/MainButton'
import useStore from '../store/RootStoreContext';
import { observer } from 'mobx-react-lite';

const AuthModal = observer(() => {

    const {userStore} = useStore()
    const {login, registration, closeModal, modalError, modalLoading} = userStore
    const {  setMessage } = useModal();

    const [tab, setTab] = useState(1)
    const [tab2, setTab2] = useState(0)

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");


    const [reg, setReg] = useState({
        email: "",
        fio: "",
        phone_number: "",
        city: "",
        comment: "",
        company_name: "",
        inn: ""
    })


    const handleSubmitEvent = async () => {
        if (email !== "" && password !== "") {
            login(email, password)
                }
    };

    const handleKeyPress = (e) => {
        console.log(e)
        if (e.key === 'Enter') {
            // Вызывайте handleSubmit при нажатии клавиши "Enter"
            handleSubmitEvent();
        }
    };

    const handleSubmitReg = (event) => {
        event.preventDefault();
        if (reg.fio !== '' && reg.phone !== '' && reg.email !== '' && tab2 == 0) {
            registration(reg, setMessage)
        }
        if (reg.fio !== '' && reg.phone !== '' && reg.email !== '' && tab2 == 1 && reg.company_name !== '' && reg.inn !== '') {
            registration(reg, setMessage)
        }

    };


    const handleChange = (event) => {
        const { name, value } = event.target
        setReg((prevData) => ({
            ...prevData,
            [name]: value,
        }))
    }



    return (
        <Modal setOpen={()=> closeModal()}>
            <div className='w-1/2 relative mx-auto mt-10'>
                <Tabs tab={tab} setTab={setTab} tabs={['регистрация', 'авториация']} className={'!w-1/2 !text-center'} />
                <div className='text-center w-full absolute text-sm text-red-500'>{modalError}</div>
            </div>
            
            {tab == 0 && (
                <Form onSubmit={handleSubmitReg}>
                    <div className='w-1/2 mx-auto flex justify-between py-4'>
                        <label className="flex gap-2 items-center text-2xl text-gray-600 cursor-pointer">
                            <input className="w-8 h-8 cursor-pointer appearance-none border bg-clip-content p-[7px] checked:bg-gray-800 checked:border-gray-600"
                                name='company'
                                onChange={() => setTab2(0)}
                                checked={tab2 == 0}
                                type="checkbox" />
                            <span class="">физическое лицо</span>
                        </label>
                        <label className="flex gap-2 items-center text-2xl text-gray-600 cursor-pointer">
                            <input className="w-8 h-8 cursor-pointer appearance-none border bg-clip-content p-[7px] checked:bg-gray-800 checked:border-gray-600"
                                name='company'
                                onChange={() => setTab2(1)}
                                checked={tab2 == 1}
                                type="checkbox" />
                            <span class="">юридическое лицо</span>
                        </label>
                    </div>
                    <div className='flex w-4/5 mx-auto gap-8 py-6'>
                        <div className='flex flex-col gap-8 w-1/2'>
                            <TextInput 
                                placeholder='ФИО' 
                                autocomplete="name" 
                                name="fio" 
                                onChange={handleChange} required={true} />
                            <InputMask 
                                mask="+7(999)-999-99-99" 
                                onChange={handleChange} 
                                className='w-full border outline-none py-3 px-6 text-xl focus:border-gray-600' 
                                placeholder="телефон" name="phone_number" required />
                            <TextInput 
                                placeholder='email'  
                                autocomplete="email" 
                                name="email" 
                                onChange={handleChange} 
                                required={true}/>
                            <TextInput 
                                placeholder='город' 
                                name="city" 
                                onChange={handleChange} 
                                required={true} />
                        </div>
                        <div className='flex flex-col gap-8 w-1/2'>
                            {tab2 == 0 && (
                                <textarea className="w-full h-full outline-none border py-3 px-6 text-lg" 
                                    name="comment" placeholder='комментарий' onChange={handleChange}></textarea>
                            )}
                            {tab2 == 1 && (
                                <>
                                    <TextInput 
                                        placeholder='название компании'
                                        name="company_name" 
                                        onChange={handleChange} 
                                        required={tab2==1} />
                                    <TextInput 
                                        placeholder='ИНН'  
                                        name="inn" 
                                        onChange={handleChange} 
                                        required={tab2==1}/>
                                    <textarea className="w-full h-full outline-none border py-3 px-6 text-lg" 
                                        name="comment" placeholder='комментарий' onChange={handleChange}></textarea>
                                </>
                            )}
                        </div>
                    </div>
                    <div className='w-1/3 pt-4 gap-8 mx-auto flex flex-col justify-center'>
                        <label className="flex justify-center gap-2 items-center text-2xl text-gray-600 cursor-pointer">
                            <input className="w-8 h-8 cursor-pointer appearance-none border bg-clip-content p-[7px] checked:bg-gray-800 checked:border-gray-600"
                                name='company'
                                type="checkbox" />
                            <span class="">подписаться на рассылку</span>
                        </label>
                        <MainButton text={modalLoading ? loader : 'регистрация'} type='submit' />
                    </div>
                </Form>
            )}
            {tab == 1 && (
                <Form className='flex mx-auto py-8 flex-col w-2/5 gap-12'>
                    <TextInput 
                        placeholder='email' 
                        autocomplete="email" 
                        onChange={(e) => setEmail(e.target.value)} 
                        onKeyUp={handleKeyPress}/>
                    <TextInput 
                        placeholder='пароль'
                        type="password"
                        onChange={(e) => setPassword(e.target.value)} 
                        onKeyUp={handleKeyPress}/>
                    <MainButton 
                        text={modalLoading ? loader : 'войти'} 
                        type='button' 
                        onClick={() => handleSubmitEvent()}/>
                </Form>
            )}

        </Modal>
    );
});

export default AuthModal;