import React from 'react';
import ReactDOM from 'react-dom/client';

import Routes from './Routes'
import ModalProvider from './provider/ModalProvider';

import './index.css';
import { RootStore } from './store/RootStore';
import { RootStoreContext } from './store/RootStoreContext';

// import './main.css';
// import './acc.css';



ReactDOM.createRoot(document.getElementById("root")).render(
  <React.StrictMode>
    <ModalProvider>
      <RootStoreContext.Provider value={new RootStore()}>
        <Routes />
      </RootStoreContext.Provider>
    </ModalProvider>

  </React.StrictMode>
);