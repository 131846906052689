import { useState } from "react";
import { findAllMatches, formatRub } from "../utils"

import { CatalogApi } from '../api'
import Modal, { ModalBlock, ModalBlockElement, ModalChangeElement } from "./window/Modal";
import ImageCard from "./image/ImageCard";
import catalogStore from "../store/apiStore/CatalogStore";
import galleryStore from "../store/apiStore/GalleryStore";
import useStore from "../store/RootStoreContext";
import { observer } from "mobx-react-lite";


export const AdditionalAttributes = ({info, pattern}) => {
    const { userStore } = useStore()

    const [response, setResponse] = useState({ ...info, ['pattern']: pattern })
    const [data, setData] = useState({ ...info, ['pattern']: pattern })

    const handleChangeAttributes = (e) => {
        const { name, value } = e.target
        const valueType = typeof (info[name])

        if (value == "") {
            setData({ ...data, [name]: null })
        } else {
            switch (valueType) {
                case 'number':
                    Number.isInteger(valueType) ?
                        setData({ ...data, [name]: parseInt(value) }) :
                        setData({ ...data, [name]: parseFloat(value) })
                    break;
                default:
                    setData({ ...data, [name]: value });
            }
        }
    }

    const handleSubmitData = async () => {
        try {
            const response = await CatalogApi.editInfo(data)
            setResponse({ ...response.data, ['pattern']: data?.pattern })
        } catch {

        }
    }
    return (
        <div className='flex justify-between pt-4'>
            {JSON.stringify(response) != JSON.stringify(data) &&
                <button className='absolute right-[54%] rounded-md p-2 border-2 hover:bg-gray-200' onClick={handleSubmitData}>сохранить</button>}
            <ModalBlock>
                {userStore.checkAdmin() ? (
                    <>
                        <ModalChangeElement name={'вес'} value={data?.weight} nameForm={'weight'} onChange={handleChangeAttributes} />
                        <ModalChangeElement name={'обьем'} value={data?.volume} nameForm={'volume'} onChange={handleChangeAttributes} />
                        <ModalChangeElement name={'размер'} value={data?.size} nameForm={'size'} onChange={handleChangeAttributes} />
                    </>

                ) : (
                    <>
                        <ModalBlockElement name={'вес'} value={data?.weight} />
                        <ModalBlockElement name={'обьем'} value={data?.volume} />
                        <ModalBlockElement name={'размер'} value={data?.size} />
                    </>
                )}
            </ModalBlock>
            <ModalBlock>
                {userStore.checkAdmin() ? (
                    <>
                        <ModalChangeElement name={'модель'} value={data?.pattern} nameForm={'pattern'} onChange={handleChangeAttributes} />
                        <ModalChangeElement name={'индекс'} value={data?.lsi} nameForm={'lsi'} onChange={handleChangeAttributes} />
                    </>
                ) : (
                    <>
                        <ModalBlockElement name={'модель'} value={data?.pattern} />
                        <ModalBlockElement name={'индекс'} value={data?.lsi} />
                    </>
                )}

            </ModalBlock>
        </div>
    );

}

export const BaseDetail = observer(() => {

    const { images } = galleryStore
    const { item, cleanItem} = catalogStore

    const { brand, type_item } = item

    return (
        <Modal setOpen={()=>cleanItem()}>
            <div class="flex gap-8 border-b pb-4 pe-8">
                <ImageCard gallery={findAllMatches(images, item.pattern)} />
                <div class="flex-col">
                    <h2 className="font-medium text-2xl">
                        {item.title}
                    </h2>
                    <div className='flex gap-4 justify-between py-6 '>
                        <ModalBlock>
                            <ModalBlockElement name={'артикул'} value={item.article} />
                            <ModalBlockElement name={'бренд'} value={brand?.brand_name} />
                            <ModalBlockElement name={'тип'} value={type_item?.type_name} />
                        </ModalBlock>
                    </div>

                    <p class="font-medium text-3xl py-4">{formatRub(item.price)}</p>
                </div>
            </div>
            <AdditionalAttributes info={item.info} pattern={item.pattern}/>
        </Modal>

    )
})

export default BaseDetail;