import React from 'react';
import { Outlet, useMatches } from 'react-router-dom';
import Breadcrumbs from '../components/Breadcrumbs';


const BaseCart = () => {
    return (
        <div className='mt-5'>
            <Breadcrumbs/>
        <Outlet />
        </div>
        
    );
};

export default BaseCart;