import React, {  useEffect } from 'react';
import Filters from '../../components/Filters'
import Pagination from '../../components/Pagination';
import { useSearchParams } from 'react-router-dom';
import { findFirstMatch, formatRub, formatEuro } from '../../utils';
import Search from '../../components/Search';
import BaseDetail from '../../components/Detail';
import BreadCrumbs from '../../components/Breadcrumbs'
import Table, { TableCell, TableRow, Tbody, Thead } from '../../components/table/Table';
import catalogStore from '../../store/apiStore/CatalogStore';
import galleryStore from '../../store/apiStore/GalleryStore';
import { observer } from 'mobx-react-lite';


const Catalog = observer(() => {

    const [params, setParams] = useSearchParams()
    const {
        items, item, pages, brands, types, 
        fetchGetCatalog, fetchGetFilters, fetchGetItem} = catalogStore
    const {images, fetchGalleryFromAPI} = galleryStore


    useEffect(()=>{
        fetchGetCatalog()
        fetchGetFilters()
        fetchGalleryFromAPI()
    }, [])

    useEffect(()=>{
        fetchGetCatalog(params)
    }, [params])


    return (
        <>
            {item && <BaseDetail/>}
            <Search />
            <BreadCrumbs />
            <section class="catalog">
                <div class="container mx-auto">
                    <div class="py-6 flex gap-10" >
                        <Filters types={types} brands={brands} />
                        <Table Pagination={<Pagination total={pages} />}>
                            <Thead>
                                <TableCell>артикул</TableCell>
                                <TableCell className={'w-16'}></TableCell>
                                <TableCell>название</TableCell>
                                <TableCell>тип</TableCell>
                                <TableCell>цена €</TableCell>
                                <TableCell>цена ₽</TableCell>
                            </Thead>
                            <Tbody>
                                {items.map((item, index) => (
                                    <TableRow onClick={() => fetchGetItem(item.id)}>
                                        <TableCell>
                                            <span>{item.brand?.brand_name}</span>
                                            <p>{item.article}</p>
                                        </TableCell>
                                        <TableCell>
                                            <img class="h-16 w-16" src={findFirstMatch(images, item.pattern)?.image_min || 'default_tire.svg'} alt="" />
                                        </TableCell>
                                        <TableCell> {item.title}</TableCell>
                                        <TableCell>{item.type_item?.type_name}</TableCell>
                                        <TableCell>{formatEuro(item.price_euro)}</TableCell>
                                        <TableCell>{formatRub(item.price_rub)}</TableCell>
                                    </TableRow>
                                ))}
                            </Tbody>

                        </Table>
                    </div>
                </div>
            </section>
        </>
    );
})


export { Catalog };