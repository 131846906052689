import { useOutsideClick } from "../../utils";


export const ModalBlock = ({ children }) => {
    return (
        <div class="flex flex-col gap-4 w-1/2 ">{children}</div>
    )
}

export const ModalBlockElement = ({ name, value }) => {
    return (
        <div class="flex gap-8">
            <p class="text-gray-500 w-16">{name}:</p>
            <p class="min-w-28">{value}</p>
        </div>
    )
}
export const ModalChangeElement = ({ name, value, nameForm, onChange }) => {
    return (
        <div className="flex gap-8">
            <p className="text-gray-500 w-16">{name}:</p>
            <input className="min-w-28 border-b-2 outline-none" name={nameForm} defaultValue={value}
                onChange={onChange} />
        </div>
    )
}

export const Modal = ({ setOpen, children }) => {
    const ref = useOutsideClick(() => setOpen(false));

    return (
        <modal class="overflow-y-auto overflow-x-hidden flex bg-gray-900/60 backdrop-blur-sm fixed top-0 right-0 left-0 z-50 justify-center items-center w-full md:inset-0 h-full max-h-full">
            <div ref={ref} class="relative p-4 w-3/4 max-h-full">
                <div className="relative p-8 flex flex-col bg-white shadow">
                    <button type="button" onClick={() => setOpen(false)}
                        class="text-gray-400 absolute right-6 top-6 bg-transparent rounded-lg text-sm ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white" data-modal-hide="static-modal">
                        <svg xmlns="http://www.w3.org/2000/svg" width="41" height="42" fill="none">
                            <g stroke="#86827A">
                                <path transform="matrix(.7071 .7071 -.64444 .76465 0 1)" d="M0-.5h56.569" />
                                <path transform="matrix(-.70763 .70658 -.64388 -.76513 39.967 1)" d="M0-.5h56.479" />
                            </g>
                        </svg>
                        <span class="sr-only">Закрыть</span>
                    </button>
                    {children}
                </div>
            </div>
        </modal>
    )
}

export default Modal;