import { useState, useEffect } from "react"

export const TableRow = ({ onClick, children, className }) => {
    // const [isVisible, setIsVisible] = useState(false);
    // const [coordinates, setCoordinates] = useState({ x: 0, y: 0 });


    // useEffect(() => {
    //     if (isVisible) {
    //         const timeoutId = setTimeout(() => setIsVisible(true), 2000);
    //         return () => clearTimeout(timeoutId);
    //     }
    // }, [coordinates]);

    // const handleMouseMove = (event) => {
    //     setCoordinates({ x: event.clientX, y: event.clientY + window.scrollY });
    // };

    return (
        <>
            {/* {onClick && isVisible && (
                <div className="absolute z-10 bg-[#fddb8d89] border border-gray-200 shadow-md w-74 px-3 py-1"
                    style={{ left: coordinates.x + 'px', top: coordinates.y - 30 + 'px' }}>
                    кликните, чтобы открыть информацию о товаре
                </div>

            )} */}
            <tr onClick={onClick}
                // onMouseMove={handleMouseMove}
                // onMouseEnter={()=>setIsVisible(true)}
                // onMouseLeave={()=>setIsVisible(false)}
                className={`${className} h-20 border-b text-center cursor-pointer hover:bg-[#fddb8d1a]`}>
                {children}
            </tr>
        </>

    )
}

export const TableCell = ({ className, children }) => {
    return (
        <td class={`${className} p-2 my-auto`}>
            {children}
        </td>
    )
}

export const Thead = ({ children }) => {
    return (
        <thead>
            <tr class="h-8 border-b-2 text-center border-gray-200">
                {children}
            </tr>
        </thead>
    )
}

export const Tbody = ({ children }) => {
    return (
        <tbody>
            {children}
        </tbody>
    )
}

export const Table = ({ Pagination, children }) => {
    return (
        <div class="w-full flex flex-col pb-8">
            <table class="w-full table-auto border-collapse	">
                {children}
            </table>
            {Pagination && Pagination}
        </div>
    )
}

export default Table;