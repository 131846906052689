import { Badge } from "../information/Badge"

const Tabs = ({ tab, setTab, tabs, className }) => {
    return (
        <div className='flex text-2xl w-full pb-4'>
            {tabs.map((text, index) => (

                <button className={`${tab == index && 'border-my-yellow text-gray-800'} border-b-2  pb-2 text-start outline-none ${className} w-1/3 text-gray-500 hover:text-gray-800`}
                    onClick={() => setTab(index)}>
                    {Array.isArray(text) ? (
                            <span className="relative">{text[0]}<Badge className={'!-right-6 text-lg'} text={text[1]} /></span>
                    ) : (
                        <>{text}</>
                    )}
                </button>

            ))}
        </div>
    )
}

export default Tabs