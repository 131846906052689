import React, { useEffect, useState } from 'react';
import Tabs from '../../components/button/Tabs';
import { Table, TableCell, TableRow, Tbody, Thead } from '../../components/table/Table'
import { Checkbox } from '../../components/input/CheckboxInput';
import SelectInput from '../../components/input/SelectInput';
import useStore from '../../store/RootStoreContext';
import { observer } from 'mobx-react-lite';
import clientStore from '../../store/apiStore/ClientStore';
import { Link } from 'react-router-dom';

const ManagerForm = observer(({ defaultValue, onChange }) => {
    const { managers } = clientStore
    return (
        <TableCell>
            {managers.length > 0 && (
                <SelectInput defaultValue={defaultValue} onChange={onChange}>
                    {managers.map(manager => (
                        <option value={manager.id}>{manager.fio}</option>
                    ))}
                </SelectInput>)}
        </TableCell>
    )
})


const ClientForm = observer(({ user }) => {
    const { userStore } = useStore()
    const { activateUser, deleteUser } = clientStore

    const [staff, setStaff] = useState(false)
    const [manager, setManager] = useState(user.manager_id)

    return (
        <>{userStore.checkAdmin() &&
            <>
                <TableCell>
                    <Checkbox classSize={'!w-5 !h-5'} onChange={() => setStaff(!staff)} checked={staff} />
                </TableCell>
                <ManagerForm defaultValue={manager} onChange={(e) => setManager(e.target.value)} />
            </>}

            <TableCell>
                <button type='button' onClick={() => activateUser(user, manager, staff)}
                    className='hover:bg-white hover:shadow-md p-1 px-2'>{'\u2714'}</button>
                <button type='button' onClick={() => deleteUser(user.email)}
                    className='hover:bg-white hover:shadow-md p-1 px-2'>{'\u2716'}</button>
            </TableCell>
        </>
    )
})

const Clients = observer(() => {

    const [tab, setTab] = useState(0)
    const { userStore } = useStore()
    const {
        clients, notAuthClients,
        fetchGetClients, fetchGetManagers, searchUser, changeManager
    } = clientStore

    useEffect(() => {
        fetchGetClients()
        if (userStore.checkAdmin()) {
            fetchGetManagers()
        }
    }, [])




    return (
        <section class="orders">
            <div class="container mx-auto">
                <div class="flex flex-col py-6">
                    <Tabs tab={tab} setTab={setTab} tabs={['Пользователи', 'Ждут авторизации']} />
                    {tab == 0 && (
                        <>
                            <div class="relative h-11 w-[300px] min-w-[200px]">
                                <input placeholder="email\компания\ФИО" onChange={(e) => searchUser(e.target.value)}
                                    class="peer h-full w-full border-b border-blue-gray-200 bg-transparent pt-4 pb-1.5 font-sans text-sm font-normal text-blue-gray-700 outline outline-0 transition-all placeholder-shown:border-blue-gray-200 focus:border-gray-500 focus:outline-0 disabled:border-0 disabled:bg-blue-gray-50 placeholder:opacity-0 focus:placeholder:opacity-100" />
                                <label
                                    class="after:content[''] pointer-events-none absolute left-0  -top-1.5 flex h-full w-[300px] select-none !overflow-visible truncate text-[11px] font-normal leading-tight text-gray-700 transition-all after:absolute after:-bottom-1.5 after:block after:w-full after:scale-x-0 after:border-b-2 after:border-gray-500 after:transition-transform after:duration-300 peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[4.25] peer-placeholder-shown:text-blue-gray-500 peer-focus:text-[11px] peer-focus:leading-tight peer-focus:text-gray-900 peer-focus:after:scale-x-100 peer-focus:after:border-gray-900 peer-disabled:text-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500">
                                    поиск пользователя
                                </label>
                            </div>
                            <Table>
                                <Thead>
                                    <TableCell>фио</TableCell>
                                    <TableCell>телефон</TableCell>
                                    <TableCell>почта</TableCell>
                                    <TableCell>компания</TableCell>
                                    <TableCell>инн</TableCell>
                                    {userStore.checkAdmin() && <TableCell>менеджер</TableCell>}
                                    <TableCell>корзина</TableCell>
                                </Thead>
                                <Tbody>
                                    {clients.map((user_) => (
                                        <TableRow>
                                            <TableCell>{user_.person?.fio}</TableCell>
                                            <TableCell>{user_.person?.phone_number}</TableCell>
                                            <TableCell>{user_.email}</TableCell>
                                            <TableCell>{user_.company?.company_name}</TableCell>
                                            <TableCell>{user_.company?.inn}</TableCell>
                                            {userStore.checkAdmin() &&
                                                <ManagerForm defaultValue={user_.manager_id} onChange={(e)=>changeManager(user_, e.target.value)}/>
                                            }
                                            <TableCell>
                                                <Link to={`/clients/${user_.id}/cart`}>
                                                       <img src="/images/profile/icon-cart.svg" class="h-8 w-8 mx-auto hover:scale-110" alt="icon-cart" />       
                                                </Link>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </Tbody>
                            </Table>
                        </>
                    )}
                    {tab == 1 && (
                        <Table>
                            <Thead>
                                <TableCell>фио</TableCell>
                                <TableCell>телефон</TableCell>
                                <TableCell>почта</TableCell>
                                <TableCell>компания</TableCell>
                                <TableCell>инн</TableCell>
                                {userStore.checkAdmin() &&
                                    <>
                                        <TableCell>сотрудник</TableCell>
                                        <TableCell>менеджер</TableCell>
                                    </>}

                                <TableCell>активация</TableCell>
                            </Thead>
                            {notAuthClients.map((user) => (
                                <Tbody>
                                    <TableRow>
                                        <TableCell>{user.person?.fio}</TableCell>
                                        <TableCell>{user.person?.phone_number}</TableCell>
                                        <TableCell>{user.email}</TableCell>
                                        <TableCell>{user.company?.company_name}</TableCell>
                                        <TableCell>{user.company?.inn}</TableCell>
                                        <ClientForm user={user} />
                                    </TableRow>
                                </Tbody>

                            ))}
                        </Table>
                    )}

                </div>
            </div>
        </section>
    );
});

export default Clients;