export const Document = () => (
    <>
        <meta httpEquiv="Content-Type" content="text/html; charset=utf-8" />
        <meta name="Generator" content="Microsoft Word 15 (filtered)" />
        <style
            dangerouslySetInnerHTML={{
                __html:
                    '\n<!--\n /* Font Definitions */\n @font-face\n\t{font-family:SimSun;\n\tpanose-1:2 1 6 0 3 1 1 1 1 1;}\n@font-face\n\t{font-family:"Cambria Math";\n\tpanose-1:2 4 5 3 5 4 6 3 2 4;}\n@font-face\n\t{font-family:Calibri;\n\tpanose-1:2 15 5 2 2 2 4 3 2 4;}\n@font-face\n\t{font-family:Georgia;\n\tpanose-1:2 4 5 2 5 4 5 2 3 3;}\n@font-face\n\t{font-family:"\\@SimSun";\n\tpanose-1:2 1 6 0 3 1 1 1 1 1;}\n /* Style Definitions */\n p.MsoNormal, li.MsoNormal, div.MsoNormal\n\t{margin:0in;\n\tfont-size:10.0pt;\n\tfont-family:"Calibri",sans-serif;}\np.MsoHeader, li.MsoHeader, div.MsoHeader\n\t{mso-style-link:"Header Char";\n\tmargin:0in;\n\tfont-size:10.0pt;\n\tfont-family:"Calibri",sans-serif;}\nspan.HeaderChar\n\t{mso-style-name:"Header Char";\n\tmso-style-link:Header;}\n.MsoChpDefault\n\t{font-size:10.0pt;\n\tfont-family:"Calibri",sans-serif;}\n /* Page Definitions */\n @page WordSection1\n\t{size:595.3pt 841.9pt;\n\tmargin:42.55pt 28.35pt 28.4pt 70.9pt;}\ndiv.WordSection1\n\t{page:WordSection1;}\n-->\n'
            }}
        />
        <div className="WordSection1">
            <p
                className="MsoNormal"
                align="center"
                style={{ marginBottom: "12.0pt", textAlign: "center", border: "none" }}
            >
                <b>
                    <span
                        lang="RU"
                        style={{
                            fontSize: "12.0pt",
                            fontFamily: '"Arial",sans-serif',
                            fontVariant: "small-caps",
                            color: "black"
                        }}
                    >
                        Карточка&nbsp; предприятия
                    </span>
                </b>
            </p>
            <table
                className={1}
                border={1}
                cellSpacing={0}
                cellPadding={0}
                width={669}
                style={{
                    marginLeft: "auto",
                    marginRight: "auto",
                    borderCollapse: "collapse",
                    border: "none"
                }}
            >
                <tbody>
                    <tr style={{ height: "39.2pt" }}>
                        <td
                            width={300}
                            colSpan={2}
                            style={{
                                width: "225.15pt",
                                borderTop: "double windowtext 1.5pt",
                                borderLeft: "none",
                                borderBottom: "dotted windowtext 1.0pt",
                                borderRight: "dotted windowtext 1.0pt",
                                padding: "0in 5.4pt 0in 5.4pt",
                                height: "39.2pt"
                            }}
                        >
                            <p
                                className="MsoNormal"
                                style={{
                                    marginTop: "3.0pt",
                                    marginRight: "0in",
                                    marginBottom: "3.0pt",
                                    marginLeft: "0in",
                                    border: "none"
                                }}
                            >
                                <b>
                                    <span
                                        lang="RU"
                                        style={{
                                            fontSize: "11.0pt",
                                            fontFamily: '"Arial",sans-serif',
                                            color: "black"
                                        }}
                                    >
                                        Полное Наименование
                                    </span>
                                </b>
                            </p>
                        </td>
                        <td
                            width={369}
                            colSpan={2}
                            style={{
                                width: "276.4pt",
                                borderTop: "double windowtext 1.5pt",
                                borderLeft: "none",
                                borderBottom: "dotted windowtext 1.0pt",
                                borderRight: "none",
                                padding: "0in 5.4pt 0in 5.4pt",
                                height: "39.2pt"
                            }}
                        >
                            <p
                                className="MsoNormal"
                                style={{
                                    marginTop: "3.0pt",
                                    marginRight: "0in",
                                    marginBottom: "3.0pt",
                                    marginLeft: "0in",
                                    border: "none"
                                }}
                            >
                                <span
                                    lang="RU"
                                    style={{
                                        fontSize: "11.0pt",
                                        fontFamily: '"Arial",sans-serif',
                                        color: "black"
                                    }}
                                >
                                    Общество с ограниченной ответственностью «БАЛТАЙР СПБ»
                                </span>
                            </p>
                        </td>
                    </tr>
                    <tr style={{ height: "39.7pt" }}>
                        <td
                            width={300}
                            colSpan={2}
                            style={{
                                width: "225.15pt",
                                borderTop: "none",
                                borderLeft: "none",
                                borderBottom: "dotted windowtext 1.0pt",
                                borderRight: "dotted windowtext 1.0pt",
                                padding: "0in 5.4pt 0in 5.4pt",
                                height: "39.7pt"
                            }}
                        >
                            <p
                                className="MsoNormal"
                                style={{
                                    marginTop: "3.0pt",
                                    marginRight: "0in",
                                    marginBottom: "3.0pt",
                                    marginLeft: "0in",
                                    border: "none"
                                }}
                            >
                                <b>
                                    <span
                                        lang="RU"
                                        style={{
                                            fontSize: "11.0pt",
                                            fontFamily: '"Arial",sans-serif',
                                            color: "black"
                                        }}
                                    >
                                        Сокращённое наименование
                                    </span>
                                </b>
                            </p>
                        </td>
                        <td
                            width={369}
                            colSpan={2}
                            style={{
                                width: "276.4pt",
                                border: "none",
                                borderBottom: "dotted windowtext 1.0pt",
                                padding: "0in 5.4pt 0in 5.4pt",
                                height: "39.7pt"
                            }}
                        >
                            <p
                                className="MsoNormal"
                                style={{
                                    marginTop: "3.0pt",
                                    marginRight: "0in",
                                    marginBottom: "3.0pt",
                                    marginLeft: "0in",
                                    border: "none"
                                }}
                            >
                                <span
                                    lang="RU"
                                    style={{
                                        fontSize: "11.0pt",
                                        fontFamily: '"Arial",sans-serif',
                                        color: "black"
                                    }}
                                >
                                    ООО «БАЛТАЙР СПБ»
                                </span>
                            </p>
                        </td>
                    </tr>
                    <tr style={{ height: "19.85pt" }}>
                        <td
                            width={300}
                            colSpan={2}
                            style={{
                                width: "225.15pt",
                                borderTop: "none",
                                borderLeft: "none",
                                borderBottom: "dotted windowtext 1.0pt",
                                borderRight: "dotted windowtext 1.0pt",
                                padding: "0in 5.4pt 0in 5.4pt",
                                height: "19.85pt"
                            }}
                        >
                            <p
                                className="MsoNormal"
                                style={{
                                    marginTop: "3.0pt",
                                    marginRight: "0in",
                                    marginBottom: "3.0pt",
                                    marginLeft: "0in",
                                    border: "none"
                                }}
                            >
                                <b>
                                    <span
                                        lang="RU"
                                        style={{
                                            fontSize: "11.0pt",
                                            fontFamily: '"Arial",sans-serif',
                                            color: "black"
                                        }}
                                    >
                                        Юридический адрес
                                    </span>
                                </b>
                                <a name="_gjdgxs" />
                            </p>
                        </td>
                        <td
                            width={369}
                            colSpan={2}
                            style={{
                                width: "276.4pt",
                                border: "none",
                                borderBottom: "dotted windowtext 1.0pt",
                                padding: "0in 5.4pt 0in 5.4pt",
                                height: "19.85pt"
                            }}
                        >
                            <p
                                className="MsoNormal"
                                style={{
                                    marginTop: "3.0pt",
                                    marginRight: "0in",
                                    marginBottom: "3.0pt",
                                    marginLeft: "0in",
                                    border: "none"
                                }}
                            >
                                <span
                                    lang="RU"
                                    style={{
                                        fontSize: "11.0pt",
                                        fontFamily: '"Arial",sans-serif',
                                        color: "black"
                                    }}
                                >
                                    196084, Россия, г. Санкт-Петербург, <br />
                                    наб. Обводного канала, д. 92, литер А, офис 215, комната №4, ч/п
                                    13Н&nbsp;
                                </span>
                            </p>
                        </td>
                    </tr>
                    <tr style={{ height: "19.85pt" }}>
                        <td
                            width={300}
                            colSpan={2}
                            style={{
                                width: "225.15pt",
                                borderTop: "none",
                                borderLeft: "none",
                                borderBottom: "dotted windowtext 1.0pt",
                                borderRight: "dotted windowtext 1.0pt",
                                padding: "0in 5.4pt 0in 5.4pt",
                                height: "19.85pt"
                            }}
                        >
                            <p
                                className="MsoNormal"
                                style={{
                                    marginTop: "3.0pt",
                                    marginRight: "0in",
                                    marginBottom: "3.0pt",
                                    marginLeft: "0in",
                                    border: "none"
                                }}
                            >
                                <b>
                                    <span
                                        lang="RU"
                                        style={{
                                            fontSize: "11.0pt",
                                            fontFamily: '"Arial",sans-serif',
                                            color: "black"
                                        }}
                                    >
                                        Почтовый адрес
                                    </span>
                                </b>
                            </p>
                        </td>
                        <td
                            width={369}
                            colSpan={2}
                            style={{
                                width: "276.4pt",
                                border: "none",
                                borderBottom: "dotted windowtext 1.0pt",
                                padding: "0in 5.4pt 0in 5.4pt",
                                height: "19.85pt"
                            }}
                        >
                            <p
                                className="MsoNormal"
                                style={{
                                    marginTop: "3.0pt",
                                    marginRight: "0in",
                                    marginBottom: "3.0pt",
                                    marginLeft: "0in",
                                    border: "none"
                                }}
                            >
                                <span
                                    lang="RU"
                                    style={{
                                        fontSize: "11.0pt",
                                        fontFamily: '"Arial",sans-serif',
                                        color: "black"
                                    }}
                                >
                                    196084, Россия, г. Санкт-Петербург, Набережная Обводного канала,
                                    д. 92, литер А, офис 215
                                </span>
                            </p>
                        </td>
                    </tr>
                    <tr style={{ height: "19.85pt" }}>
                        <td
                            width={300}
                            colSpan={2}
                            style={{
                                width: "225.15pt",
                                borderTop: "none",
                                borderLeft: "none",
                                borderBottom: "dotted windowtext 1.0pt",
                                borderRight: "dotted windowtext 1.0pt",
                                padding: "0in 5.4pt 0in 5.4pt",
                                height: "19.85pt"
                            }}
                        >
                            <p
                                className="MsoNormal"
                                style={{
                                    marginTop: "3.0pt",
                                    marginRight: "0in",
                                    marginBottom: "3.0pt",
                                    marginLeft: "0in",
                                    border: "none"
                                }}
                            >
                                <b>
                                    <span
                                        lang="RU"
                                        style={{
                                            fontSize: "11.0pt",
                                            fontFamily: '"Arial",sans-serif',
                                            color: "black"
                                        }}
                                    >
                                        Телефон/факс
                                    </span>
                                </b>
                            </p>
                        </td>
                        <td
                            width={369}
                            colSpan={2}
                            style={{
                                width: "276.4pt",
                                border: "none",
                                borderBottom: "dotted windowtext 1.0pt",
                                padding: "0in 5.4pt 0in 5.4pt",
                                height: "19.85pt"
                            }}
                        >
                            <p
                                className="MsoNormal"
                                style={{
                                    marginTop: "3.0pt",
                                    marginRight: "0in",
                                    marginBottom: "3.0pt",
                                    marginLeft: "0in",
                                    border: "none"
                                }}
                            >
                                <span
                                    lang="RU"
                                    style={{
                                        fontSize: "11.0pt",
                                        fontFamily: '"Arial",sans-serif',
                                        color: "black"
                                    }}
                                >
                                    +7 (800) 555 2712
                                </span>
                            </p>
                        </td>
                    </tr>
                    <tr style={{ height: "19.85pt" }}>
                        <td
                            width={300}
                            colSpan={2}
                            style={{
                                width: "225.15pt",
                                borderTop: "none",
                                borderLeft: "none",
                                borderBottom: "dotted windowtext 1.0pt",
                                borderRight: "dotted windowtext 1.0pt",
                                padding: "0in 5.4pt 0in 5.4pt",
                                height: "19.85pt"
                            }}
                        >
                            <p
                                className="MsoNormal"
                                style={{
                                    marginTop: "3.0pt",
                                    marginRight: "0in",
                                    marginBottom: "3.0pt",
                                    marginLeft: "0in",
                                    border: "none"
                                }}
                            >
                                <b>
                                    <span
                                        lang="RU"
                                        style={{
                                            fontSize: "11.0pt",
                                            fontFamily: '"Arial",sans-serif',
                                            color: "black"
                                        }}
                                    >
                                        ИНН/КПП
                                    </span>
                                </b>
                            </p>
                        </td>
                        <td
                            width={369}
                            colSpan={2}
                            style={{
                                width: "276.4pt",
                                border: "none",
                                borderBottom: "dotted windowtext 1.0pt",
                                padding: "0in 5.4pt 0in 5.4pt",
                                height: "19.85pt"
                            }}
                        >
                            <p
                                className="MsoNormal"
                                style={{
                                    marginTop: "3.0pt",
                                    marginRight: "0in",
                                    marginBottom: "3.0pt",
                                    marginLeft: "0in",
                                    border: "none"
                                }}
                            >
                                <span
                                    lang="RU"
                                    style={{
                                        fontSize: "11.0pt",
                                        fontFamily: '"Arial",sans-serif',
                                        color: "black"
                                    }}
                                >
                                    7810901609/
                                </span>
                                <span
                                    lang="RU"
                                    style={{ fontFamily: '"Arial",sans-serif', color: "black" }}
                                >
                                    {" "}
                                </span>
                                <span
                                    lang="RU"
                                    style={{
                                        fontSize: "11.0pt",
                                        fontFamily: '"Arial",sans-serif',
                                        color: "black"
                                    }}
                                >
                                    781001001
                                </span>
                            </p>
                        </td>
                    </tr>
                    <tr style={{ height: "19.85pt" }}>
                        <td
                            width={300}
                            colSpan={2}
                            style={{
                                width: "225.15pt",
                                borderTop: "none",
                                borderLeft: "none",
                                borderBottom: "dotted windowtext 1.0pt",
                                borderRight: "dotted windowtext 1.0pt",
                                padding: "0in 5.4pt 0in 5.4pt",
                                height: "19.85pt"
                            }}
                        >
                            <p
                                className="MsoNormal"
                                style={{
                                    marginTop: "3.0pt",
                                    marginRight: "0in",
                                    marginBottom: "3.0pt",
                                    marginLeft: "0in",
                                    border: "none"
                                }}
                            >
                                <b>
                                    <span
                                        lang="RU"
                                        style={{
                                            fontSize: "11.0pt",
                                            fontFamily: '"Arial",sans-serif',
                                            color: "black"
                                        }}
                                    >
                                        ОГРН
                                    </span>
                                </b>
                            </p>
                        </td>
                        <td
                            width={369}
                            colSpan={2}
                            style={{
                                width: "276.4pt",
                                border: "none",
                                borderBottom: "dotted windowtext 1.0pt",
                                padding: "0in 5.4pt 0in 5.4pt",
                                height: "19.85pt"
                            }}
                        >
                            <p
                                className="MsoNormal"
                                style={{
                                    marginTop: "3.0pt",
                                    marginRight: "0in",
                                    marginBottom: "3.0pt",
                                    marginLeft: "0in",
                                    border: "none"
                                }}
                            >
                                <span
                                    lang="RU"
                                    style={{
                                        fontSize: "11.0pt",
                                        fontFamily: '"Arial",sans-serif',
                                        color: "black"
                                    }}
                                >
                                    1207800105391
                                </span>
                            </p>
                        </td>
                    </tr>
                    <tr style={{ height: "19.85pt" }}>
                        <td
                            width={300}
                            colSpan={2}
                            style={{
                                width: "225.15pt",
                                borderTop: "none",
                                borderLeft: "none",
                                borderBottom: "dotted windowtext 1.0pt",
                                borderRight: "dotted windowtext 1.0pt",
                                padding: "0in 5.4pt 0in 5.4pt",
                                height: "19.85pt"
                            }}
                        >
                            <p
                                className="MsoNormal"
                                style={{
                                    marginTop: "3.0pt",
                                    marginRight: "0in",
                                    marginBottom: "3.0pt",
                                    marginLeft: "0in",
                                    border: "none"
                                }}
                            >
                                <b>
                                    <span
                                        lang="RU"
                                        style={{
                                            fontSize: "11.0pt",
                                            fontFamily: '"Arial",sans-serif',
                                            color: "black"
                                        }}
                                    >
                                        Расчётный счёт
                                    </span>
                                </b>
                            </p>
                        </td>
                        <td
                            width={369}
                            colSpan={2}
                            style={{
                                width: "276.4pt",
                                border: "none",
                                borderBottom: "dotted windowtext 1.0pt",
                                padding: "0in 5.4pt 0in 5.4pt",
                                height: "19.85pt"
                            }}
                        >
                            <p
                                className="MsoNormal"
                                style={{
                                    marginTop: "3.0pt",
                                    marginRight: "0in",
                                    marginBottom: "3.0pt",
                                    marginLeft: "0in",
                                    border: "none"
                                }}
                            >
                                <span
                                    lang="RU"
                                    style={{ fontSize: "11.0pt", fontFamily: '"Arial",sans-serif' }}
                                >
                                    40702810990320004456
                                </span>
                            </p>
                        </td>
                    </tr>
                    <tr style={{ height: "19.85pt" }}>
                        <td
                            width={300}
                            colSpan={2}
                            style={{
                                width: "225.15pt",
                                borderTop: "none",
                                borderLeft: "none",
                                borderBottom: "dotted windowtext 1.0pt",
                                borderRight: "dotted windowtext 1.0pt",
                                padding: "0in 5.4pt 0in 5.4pt",
                                height: "19.85pt"
                            }}
                        >
                            <p
                                className="MsoNormal"
                                style={{
                                    marginTop: "3.0pt",
                                    marginRight: "0in",
                                    marginBottom: "3.0pt",
                                    marginLeft: "0in",
                                    border: "none"
                                }}
                            >
                                <b>
                                    <span
                                        lang="RU"
                                        style={{
                                            fontSize: "11.0pt",
                                            fontFamily: '"Arial",sans-serif',
                                            color: "black"
                                        }}
                                    >
                                        Корреспондентский счёт
                                    </span>
                                </b>
                            </p>
                        </td>
                        <td
                            width={369}
                            colSpan={2}
                            style={{
                                width: "276.4pt",
                                border: "none",
                                borderBottom: "dotted windowtext 1.0pt",
                                padding: "0in 5.4pt 0in 5.4pt",
                                height: "19.85pt"
                            }}
                        >
                            <p
                                className="MsoNormal"
                                style={{
                                    marginTop: "3.0pt",
                                    marginRight: "0in",
                                    marginBottom: "3.0pt",
                                    marginLeft: "0in",
                                    border: "none"
                                }}
                            >
                                <span
                                    lang="RU"
                                    style={{ fontSize: "11.0pt", fontFamily: '"Arial",sans-serif' }}
                                >
                                    30101810900000000790
                                </span>
                            </p>
                        </td>
                    </tr>
                    <tr style={{ height: "19.85pt" }}>
                        <td
                            width={300}
                            colSpan={2}
                            style={{
                                width: "225.15pt",
                                borderTop: "none",
                                borderLeft: "none",
                                borderBottom: "dotted windowtext 1.0pt",
                                borderRight: "dotted windowtext 1.0pt",
                                padding: "0in 5.4pt 0in 5.4pt",
                                height: "19.85pt"
                            }}
                        >
                            <p
                                className="MsoNormal"
                                style={{
                                    marginTop: "3.0pt",
                                    marginRight: "0in",
                                    marginBottom: "3.0pt",
                                    marginLeft: "0in",
                                    border: "none"
                                }}
                            >
                                <b>
                                    <span
                                        lang="RU"
                                        style={{
                                            fontSize: "11.0pt",
                                            fontFamily: '"Arial",sans-serif',
                                            color: "black"
                                        }}
                                    >
                                        БИК банка
                                    </span>
                                </b>
                            </p>
                        </td>
                        <td
                            width={369}
                            colSpan={2}
                            style={{
                                width: "276.4pt",
                                border: "none",
                                borderBottom: "dotted windowtext 1.0pt",
                                padding: "0in 5.4pt 0in 5.4pt",
                                height: "19.85pt"
                            }}
                        >
                            <p
                                className="MsoNormal"
                                style={{
                                    marginTop: "3.0pt",
                                    marginRight: "0in",
                                    marginBottom: "3.0pt",
                                    marginLeft: "0in",
                                    border: "none"
                                }}
                            >
                                <span
                                    lang="RU"
                                    style={{
                                        fontSize: "11.0pt",
                                        fontFamily: '"Arial",sans-serif',
                                        color: "black"
                                    }}
                                >
                                    044030790
                                </span>
                            </p>
                        </td>
                    </tr>
                    <tr style={{ height: "34.4pt" }}>
                        <td
                            width={300}
                            colSpan={2}
                            style={{
                                width: "225.15pt",
                                borderTop: "none",
                                borderLeft: "none",
                                borderBottom: "dotted windowtext 1.0pt",
                                borderRight: "dotted windowtext 1.0pt",
                                padding: "0in 5.4pt 0in 5.4pt",
                                height: "34.4pt"
                            }}
                        >
                            <p
                                className="MsoNormal"
                                style={{
                                    marginTop: "3.0pt",
                                    marginRight: "0in",
                                    marginBottom: "3.0pt",
                                    marginLeft: "0in",
                                    border: "none"
                                }}
                            >
                                <b>
                                    <span
                                        lang="RU"
                                        style={{
                                            fontSize: "11.0pt",
                                            fontFamily: '"Arial",sans-serif',
                                            color: "black"
                                        }}
                                    >
                                        Банк
                                    </span>
                                </b>
                            </p>
                        </td>
                        <td
                            width={369}
                            colSpan={2}
                            style={{
                                width: "276.4pt",
                                border: "none",
                                borderBottom: "dotted windowtext 1.0pt",
                                padding: "0in 5.4pt 0in 5.4pt",
                                height: "34.4pt"
                            }}
                        >
                            <p
                                className="MsoNormal"
                                style={{
                                    marginTop: "3.0pt",
                                    marginRight: "0in",
                                    marginBottom: "3.0pt",
                                    marginLeft: "0in",
                                    border: "none"
                                }}
                            >
                                <span
                                    lang="RU"
                                    style={{ fontSize: "11.0pt", fontFamily: '"Arial",sans-serif' }}
                                >
                                    ПАО «БАНК «САНКТ-ПЕТЕРБУРГ»
                                </span>
                            </p>
                            <p
                                className="MsoNormal"
                                style={{
                                    marginTop: "3.0pt",
                                    marginRight: "0in",
                                    marginBottom: "3.0pt",
                                    marginLeft: "0in",
                                    border: "none"
                                }}
                            >
                                <span
                                    lang="RU"
                                    style={{ fontSize: "11.0pt", fontFamily: '"Arial",sans-serif' }}
                                >
                                    г. Санкт-Петербург
                                </span>
                            </p>
                        </td>
                    </tr>
                    <tr style={{ height: "19.85pt" }}>
                        <td
                            width={669}
                            colSpan={4}
                            style={{
                                width: "501.55pt",
                                border: "none",
                                borderBottom: "dotted windowtext 1.0pt",
                                padding: "0in 5.4pt 0in 5.4pt",
                                height: "19.85pt"
                            }}
                        >
                            <p
                                className="MsoNormal"
                                style={{
                                    marginTop: "3.0pt",
                                    marginRight: "0in",
                                    marginBottom: "3.0pt",
                                    marginLeft: "0in",
                                    border: "none"
                                }}
                            >
                                <b>
                                    <span
                                        lang="RU"
                                        style={{
                                            fontSize: "11.0pt",
                                            fontFamily: '"Arial",sans-serif',
                                            color: "black"
                                        }}
                                    >
                                        Классификаторы в статистическом регистре
                                    </span>
                                </b>
                            </p>
                        </td>
                    </tr>
                    <tr style={{ height: "19.85pt" }}>
                        <td
                            width={140}
                            style={{
                                width: "104.65pt",
                                borderTop: "none",
                                borderLeft: "none",
                                borderBottom: "dotted windowtext 1.0pt",
                                borderRight: "dotted windowtext 1.0pt",
                                padding: "0in 5.4pt 0in 5.4pt",
                                height: "19.85pt"
                            }}
                        >
                            <p
                                className="MsoNormal"
                                style={{
                                    marginTop: "3.0pt",
                                    marginRight: "0in",
                                    marginBottom: "3.0pt",
                                    marginLeft: "0in",
                                    border: "none"
                                }}
                            >
                                <span
                                    lang="RU"
                                    style={{
                                        fontSize: "11.0pt",
                                        fontFamily: '"Arial",sans-serif',
                                        color: "black"
                                    }}
                                >
                                    ОКПО
                                </span>
                            </p>
                        </td>
                        <td
                            width={161}
                            style={{
                                width: "120.5pt",
                                borderTop: "none",
                                borderLeft: "none",
                                borderBottom: "dotted windowtext 1.0pt",
                                borderRight: "dotted windowtext 1.0pt",
                                padding: "0in 5.4pt 0in 5.4pt",
                                height: "19.85pt"
                            }}
                        >
                            <p
                                className="MsoNormal"
                                style={{
                                    marginTop: "3.0pt",
                                    marginRight: "0in",
                                    marginBottom: "3.0pt",
                                    marginLeft: "0in",
                                    border: "none"
                                }}
                            >
                                <span
                                    lang="RU"
                                    style={{
                                        fontSize: "11.0pt",
                                        fontFamily: '"Arial",sans-serif',
                                        color: "black"
                                    }}
                                >
                                    45346121
                                </span>
                            </p>
                        </td>
                        <td
                            width={170}
                            style={{
                                width: "127.55pt",
                                borderTop: "none",
                                borderLeft: "none",
                                borderBottom: "dotted windowtext 1.0pt",
                                borderRight: "dotted windowtext 1.0pt",
                                padding: "0in 5.4pt 0in 5.4pt",
                                height: "19.85pt"
                            }}
                        >
                            <p
                                className="MsoNormal"
                                style={{
                                    marginTop: "3.0pt",
                                    marginRight: "0in",
                                    marginBottom: "3.0pt",
                                    marginLeft: "0in",
                                    border: "none"
                                }}
                            >
                                <span
                                    lang="RU"
                                    style={{
                                        fontSize: "11.0pt",
                                        fontFamily: '"Arial",sans-serif',
                                        color: "black"
                                    }}
                                >
                                    ОКАТО
                                </span>
                            </p>
                        </td>
                        <td
                            width={198}
                            style={{
                                width: "148.85pt",
                                border: "none",
                                borderBottom: "dotted windowtext 1.0pt",
                                padding: "0in 5.4pt 0in 5.4pt",
                                height: "19.85pt"
                            }}
                        >
                            <p
                                className="MsoNormal"
                                style={{
                                    marginTop: "3.0pt",
                                    marginRight: "0in",
                                    marginBottom: "3.0pt",
                                    marginLeft: "0in",
                                    border: "none"
                                }}
                            >
                                <span
                                    lang="RU"
                                    style={{
                                        fontSize: "11.0pt",
                                        fontFamily: '"Arial",sans-serif',
                                        color: "black"
                                    }}
                                >
                                    40284000000
                                </span>
                            </p>
                        </td>
                    </tr>
                    <tr style={{ height: "19.85pt" }}>
                        <td
                            width={140}
                            style={{
                                width: "104.65pt",
                                borderTop: "none",
                                borderLeft: "none",
                                borderBottom: "dotted windowtext 1.0pt",
                                borderRight: "dotted windowtext 1.0pt",
                                padding: "0in 5.4pt 0in 5.4pt",
                                height: "19.85pt"
                            }}
                        >
                            <p
                                className="MsoNormal"
                                style={{
                                    marginTop: "3.0pt",
                                    marginRight: "0in",
                                    marginBottom: "3.0pt",
                                    marginLeft: "0in",
                                    border: "none"
                                }}
                            >
                                <span
                                    lang="RU"
                                    style={{
                                        fontSize: "11.0pt",
                                        fontFamily: '"Arial",sans-serif',
                                        color: "black"
                                    }}
                                >
                                    ОКТМО
                                </span>
                            </p>
                        </td>
                        <td
                            width={161}
                            style={{
                                width: "120.5pt",
                                borderTop: "none",
                                borderLeft: "none",
                                borderBottom: "dotted windowtext 1.0pt",
                                borderRight: "dotted windowtext 1.0pt",
                                padding: "0in 5.4pt 0in 5.4pt",
                                height: "19.85pt"
                            }}
                        >
                            <p
                                className="MsoNormal"
                                style={{
                                    marginTop: "3.0pt",
                                    marginRight: "0in",
                                    marginBottom: "3.0pt",
                                    marginLeft: "0in",
                                    border: "none"
                                }}
                            >
                                <span
                                    lang="RU"
                                    style={{
                                        fontSize: "11.0pt",
                                        fontFamily: '"Arial",sans-serif',
                                        color: "black"
                                    }}
                                >
                                    40373000000
                                </span>
                            </p>
                        </td>
                        <td
                            width={170}
                            style={{
                                width: "127.55pt",
                                borderTop: "none",
                                borderLeft: "none",
                                borderBottom: "dotted windowtext 1.0pt",
                                borderRight: "dotted windowtext 1.0pt",
                                padding: "0in 5.4pt 0in 5.4pt",
                                height: "19.85pt"
                            }}
                        >
                            <p
                                className="MsoNormal"
                                style={{
                                    marginTop: "3.0pt",
                                    marginRight: "0in",
                                    marginBottom: "3.0pt",
                                    marginLeft: "0in",
                                    border: "none"
                                }}
                            >
                                <span
                                    lang="RU"
                                    style={{
                                        fontSize: "11.0pt",
                                        fontFamily: '"Arial",sans-serif',
                                        color: "black"
                                    }}
                                >
                                    ОКОГУ
                                </span>
                            </p>
                        </td>
                        <td
                            width={198}
                            style={{
                                width: "148.85pt",
                                border: "none",
                                borderBottom: "dotted windowtext 1.0pt",
                                padding: "0in 5.4pt 0in 5.4pt",
                                height: "19.85pt"
                            }}
                        >
                            <p
                                className="MsoNormal"
                                style={{
                                    marginTop: "3.0pt",
                                    marginRight: "0in",
                                    marginBottom: "3.0pt",
                                    marginLeft: "0in",
                                    border: "none"
                                }}
                            >
                                <span
                                    lang="RU"
                                    style={{
                                        fontSize: "11.0pt",
                                        fontFamily: '"Arial",sans-serif',
                                        color: "black"
                                    }}
                                >
                                    4210011
                                </span>
                            </p>
                        </td>
                    </tr>
                    <tr style={{ height: "19.85pt" }}>
                        <td
                            width={140}
                            style={{
                                width: "104.65pt",
                                borderTop: "none",
                                borderLeft: "none",
                                borderBottom: "dotted windowtext 1.0pt",
                                borderRight: "dotted windowtext 1.0pt",
                                padding: "0in 5.4pt 0in 5.4pt",
                                height: "19.85pt"
                            }}
                        >
                            <p
                                className="MsoNormal"
                                style={{
                                    marginTop: "3.0pt",
                                    marginRight: "0in",
                                    marginBottom: "3.0pt",
                                    marginLeft: "0in",
                                    border: "none"
                                }}
                            >
                                <span
                                    lang="RU"
                                    style={{
                                        fontSize: "11.0pt",
                                        fontFamily: '"Arial",sans-serif',
                                        color: "black"
                                    }}
                                >
                                    ОКФС
                                </span>
                            </p>
                        </td>
                        <td
                            width={161}
                            style={{
                                width: "120.5pt",
                                borderTop: "none",
                                borderLeft: "none",
                                borderBottom: "dotted windowtext 1.0pt",
                                borderRight: "dotted windowtext 1.0pt",
                                padding: "0in 5.4pt 0in 5.4pt",
                                height: "19.85pt"
                            }}
                        >
                            <p
                                className="MsoNormal"
                                style={{
                                    marginTop: "3.0pt",
                                    marginRight: "0in",
                                    marginBottom: "3.0pt",
                                    marginLeft: "0in",
                                    border: "none"
                                }}
                            >
                                <span
                                    lang="RU"
                                    style={{
                                        fontSize: "11.0pt",
                                        fontFamily: '"Arial",sans-serif',
                                        color: "black"
                                    }}
                                >
                                    24
                                </span>
                            </p>
                        </td>
                        <td
                            width={170}
                            style={{
                                width: "127.55pt",
                                borderTop: "none",
                                borderLeft: "none",
                                borderBottom: "dotted windowtext 1.0pt",
                                borderRight: "dotted windowtext 1.0pt",
                                padding: "0in 5.4pt 0in 5.4pt",
                                height: "19.85pt"
                            }}
                        >
                            <p
                                className="MsoNormal"
                                style={{
                                    marginTop: "3.0pt",
                                    marginRight: "0in",
                                    marginBottom: "3.0pt",
                                    marginLeft: "0in",
                                    border: "none"
                                }}
                            >
                                <span
                                    lang="RU"
                                    style={{
                                        fontSize: "11.0pt",
                                        fontFamily: '"Arial",sans-serif',
                                        color: "black"
                                    }}
                                >
                                    ОКОПФ
                                </span>
                            </p>
                        </td>
                        <td
                            width={198}
                            style={{
                                width: "148.85pt",
                                border: "none",
                                borderBottom: "dotted windowtext 1.0pt",
                                padding: "0in 5.4pt 0in 5.4pt",
                                height: "19.85pt"
                            }}
                        >
                            <p
                                className="MsoNormal"
                                style={{
                                    marginTop: "3.0pt",
                                    marginRight: "0in",
                                    marginBottom: "3.0pt",
                                    marginLeft: "0in",
                                    border: "none"
                                }}
                            >
                                <span
                                    lang="RU"
                                    style={{
                                        fontSize: "11.0pt",
                                        fontFamily: '"Arial",sans-serif',
                                        color: "black"
                                    }}
                                >
                                    12300
                                </span>
                            </p>
                        </td>
                    </tr>
                    <tr style={{ height: "19.85pt" }}>
                        <td
                            width={140}
                            style={{
                                width: "104.65pt",
                                borderTop: "none",
                                borderLeft: "none",
                                borderBottom: "dotted windowtext 1.0pt",
                                borderRight: "dotted windowtext 1.0pt",
                                padding: "0in 5.4pt 0in 5.4pt",
                                height: "19.85pt"
                            }}
                        >
                            <p
                                className="MsoNormal"
                                style={{
                                    marginTop: "3.0pt",
                                    marginRight: "0in",
                                    marginBottom: "3.0pt",
                                    marginLeft: "0in",
                                    border: "none"
                                }}
                            >
                                <span
                                    lang="RU"
                                    style={{
                                        fontSize: "11.0pt",
                                        fontFamily: '"Arial",sans-serif',
                                        color: "black"
                                    }}
                                >
                                    ОКВЭД
                                </span>
                            </p>
                        </td>
                        <td
                            width={529}
                            colSpan={3}
                            style={{
                                width: "396.9pt",
                                border: "none",
                                borderBottom: "dotted windowtext 1.0pt",
                                padding: "0in 5.4pt 0in 5.4pt",
                                height: "19.85pt"
                            }}
                        >
                            <p
                                className="MsoNormal"
                                style={{
                                    marginTop: "3.0pt",
                                    marginRight: "0in",
                                    marginBottom: "3.0pt",
                                    marginLeft: "0in",
                                    border: "none"
                                }}
                            >
                                <span
                                    lang="RU"
                                    style={{
                                        fontSize: "11.0pt",
                                        fontFamily: '"Arial",sans-serif',
                                        color: "black"
                                    }}
                                >
                                    45.31, 45.31.1, 45.31.2, 45.32
                                </span>
                            </p>
                        </td>
                    </tr>
                    <tr style={{ height: "39.7pt" }}>
                        <td
                            width={300}
                            colSpan={2}
                            style={{
                                width: "225.15pt",
                                borderTop: "none",
                                borderLeft: "none",
                                borderBottom: "dotted windowtext 1.0pt",
                                borderRight: "dotted windowtext 1.0pt",
                                padding: "0in 5.4pt 0in 5.4pt",
                                height: "39.7pt"
                            }}
                        >
                            <p
                                className="MsoNormal"
                                style={{
                                    marginTop: "3.0pt",
                                    marginRight: "0in",
                                    marginBottom: "3.0pt",
                                    marginLeft: "0in",
                                    border: "none"
                                }}
                            >
                                <b>
                                    <span
                                        lang="RU"
                                        style={{
                                            fontSize: "11.0pt",
                                            fontFamily: '"Arial",sans-serif',
                                            color: "black"
                                        }}
                                    >
                                        Генеральный директор
                                    </span>
                                </b>
                            </p>
                        </td>
                        <td
                            width={369}
                            colSpan={2}
                            style={{
                                width: "276.4pt",
                                border: "none",
                                borderBottom: "dotted windowtext 1.0pt",
                                padding: "0in 5.4pt 0in 5.4pt",
                                height: "39.7pt"
                            }}
                        >
                            <p
                                className="MsoNormal"
                                style={{
                                    marginTop: "3.0pt",
                                    marginRight: "0in",
                                    marginBottom: "3.0pt",
                                    marginLeft: "0in",
                                    border: "none"
                                }}
                            >
                                <span
                                    lang="RU"
                                    style={{
                                        fontSize: "11.0pt",
                                        fontFamily: '"Arial",sans-serif',
                                        color: "black"
                                    }}
                                >
                                    Самарин Максим Викторович
                                </span>
                            </p>
                            <p
                                className="MsoNormal"
                                style={{
                                    marginTop: "3.0pt",
                                    marginRight: "0in",
                                    marginBottom: "3.0pt",
                                    marginLeft: "0in",
                                    lineHeight: "150%",
                                    border: "none"
                                }}
                            >
                                <span
                                    lang="RU"
                                    style={{
                                        fontSize: "11.0pt",
                                        lineHeight: "150%",
                                        fontFamily: '"Arial",sans-serif',
                                        color: "black"
                                    }}
                                >
                                    действует на основании <b>Устава</b>
                                </span>
                            </p>
                        </td>
                    </tr>
                    <tr style={{ height: "15.95pt" }}>
                        <td
                            width={300}
                            colSpan={2}
                            style={{
                                width: "225.15pt",
                                borderTop: "none",
                                borderLeft: "none",
                                borderBottom: "dotted windowtext 1.0pt",
                                borderRight: "dotted windowtext 1.0pt",
                                padding: "0in 5.4pt 0in 5.4pt",
                                height: "15.95pt"
                            }}
                        >
                            <p
                                className="MsoNormal"
                                style={{
                                    marginTop: "3.0pt",
                                    marginRight: "0in",
                                    marginBottom: "3.0pt",
                                    marginLeft: "0in",
                                    border: "none"
                                }}
                            >
                                <b>
                                    <span
                                        lang="RU"
                                        style={{
                                            fontSize: "11.0pt",
                                            fontFamily: '"Arial",sans-serif',
                                            color: "black"
                                        }}
                                    >
                                        E-mail:
                                    </span>
                                </b>
                            </p>
                        </td>
                        <td
                            width={369}
                            colSpan={2}
                            style={{
                                width: "276.4pt",
                                border: "none",
                                borderBottom: "dotted windowtext 1.0pt",
                                padding: "0in 5.4pt 0in 5.4pt",
                                height: "15.95pt"
                            }}
                        >
                            <p
                                className="MsoNormal"
                                style={{
                                    marginTop: "3.0pt",
                                    marginRight: "0in",
                                    marginBottom: "3.0pt",
                                    marginLeft: "0in",
                                    border: "none"
                                }}
                            >
                                <span
                                    lang="RU"
                                    style={{
                                        fontSize: "11.0pt",
                                        fontFamily: '"Arial",sans-serif',
                                        color: "black"
                                    }}
                                >
                                    office@baltyre.ru
                                </span>
                            </p>
                        </td>
                    </tr>
                    <tr style={{ height: "19.85pt" }}>
                        <td
                            width={300}
                            colSpan={2}
                            style={{
                                width: "225.15pt",
                                borderTop: "none",
                                borderLeft: "none",
                                borderBottom: "dotted windowtext 1.0pt",
                                borderRight: "dotted windowtext 1.0pt",
                                padding: "0in 5.4pt 0in 5.4pt",
                                height: "19.85pt"
                            }}
                        >
                            <p
                                className="MsoNormal"
                                style={{
                                    marginTop: "3.0pt",
                                    marginRight: "0in",
                                    marginBottom: "3.0pt",
                                    marginLeft: "0in",
                                    border: "none"
                                }}
                            >
                                <b>
                                    <span
                                        lang="RU"
                                        style={{
                                            fontSize: "11.0pt",
                                            fontFamily: '"Arial",sans-serif',
                                            color: "black"
                                        }}
                                    >
                                        Система налогообложения
                                    </span>
                                </b>
                            </p>
                        </td>
                        <td
                            width={369}
                            colSpan={2}
                            style={{
                                width: "276.4pt",
                                border: "none",
                                borderBottom: "dotted windowtext 1.0pt",
                                padding: "0in 5.4pt 0in 5.4pt",
                                height: "19.85pt"
                            }}
                        >
                            <p
                                className="MsoNormal"
                                style={{
                                    marginTop: "3.0pt",
                                    marginRight: "0in",
                                    marginBottom: "3.0pt",
                                    marginLeft: "0in",
                                    border: "none"
                                }}
                            >
                                <span
                                    lang="RU"
                                    style={{
                                        fontSize: "11.0pt",
                                        fontFamily: '"Arial",sans-serif',
                                        color: "black"
                                    }}
                                >
                                    ОСНО
                                </span>
                            </p>
                        </td>
                    </tr>
                    <tr style={{ height: "19.85pt" }}>
                        <td
                            width={300}
                            colSpan={2}
                            style={{
                                width: "225.15pt",
                                borderTop: "none",
                                borderLeft: "none",
                                borderBottom: "dotted windowtext 1.0pt",
                                borderRight: "dotted windowtext 1.0pt",
                                padding: "0in 5.4pt 0in 5.4pt",
                                height: "19.85pt"
                            }}
                        >
                            <p
                                className="MsoNormal"
                                style={{
                                    marginTop: "3.0pt",
                                    marginRight: "0in",
                                    marginBottom: "3.0pt",
                                    marginLeft: "0in",
                                    border: "none"
                                }}
                            >
                                <b>
                                    <span
                                        lang="RU"
                                        style={{
                                            fontSize: "11.0pt",
                                            fontFamily: '"Arial",sans-serif',
                                            color: "black"
                                        }}
                                    >
                                        Оператор ЭДО
                                    </span>
                                </b>
                            </p>
                        </td>
                        <td
                            width={369}
                            colSpan={2}
                            style={{
                                width: "276.4pt",
                                border: "none",
                                borderBottom: "dotted windowtext 1.0pt",
                                padding: "0in 5.4pt 0in 5.4pt",
                                height: "19.85pt"
                            }}
                        >
                            <p
                                className="MsoNormal"
                                style={{
                                    marginTop: "3.0pt",
                                    marginRight: "0in",
                                    marginBottom: "3.0pt",
                                    marginLeft: "0in",
                                    border: "none"
                                }}
                            >
                                <span
                                    lang="RU"
                                    style={{
                                        fontSize: "11.0pt",
                                        fontFamily: '"Arial",sans-serif',
                                        color: "black"
                                    }}
                                >
                                    СБИС (Компания «Тензор»)
                                </span>
                            </p>
                        </td>
                    </tr>
                    <tr style={{ height: "19.85pt" }}>
                        <td
                            width={300}
                            colSpan={2}
                            style={{
                                width: "225.15pt",
                                borderTop: "none",
                                borderLeft: "none",
                                borderBottom: "dotted windowtext 1.0pt",
                                borderRight: "dotted windowtext 1.0pt",
                                padding: "0in 5.4pt 0in 5.4pt",
                                height: "19.85pt"
                            }}
                        >
                            <p
                                className="MsoNormal"
                                style={{
                                    marginTop: "3.0pt",
                                    marginRight: "0in",
                                    marginBottom: "3.0pt",
                                    marginLeft: "0in",
                                    border: "none"
                                }}
                            >
                                <b>
                                    <span
                                        lang="RU"
                                        style={{
                                            fontSize: "11.0pt",
                                            fontFamily: '"Arial",sans-serif',
                                            color: "black"
                                        }}
                                    >
                                        Идентификатор ЭДО
                                    </span>
                                </b>
                            </p>
                        </td>
                        <td
                            width={369}
                            colSpan={2}
                            style={{
                                width: "276.4pt",
                                border: "none",
                                borderBottom: "dotted windowtext 1.0pt",
                                padding: "0in 5.4pt 0in 5.4pt",
                                height: "19.85pt"
                            }}
                        >
                            <p
                                className="MsoNormal"
                                style={{
                                    marginTop: "3.0pt",
                                    marginRight: "0in",
                                    marginBottom: "3.0pt",
                                    marginLeft: "0in",
                                    border: "none"
                                }}
                            >
                                <span
                                    lang="RU"
                                    style={{
                                        fontSize: "11.0pt",
                                        fontFamily: '"Arial",sans-serif',
                                        color: "black"
                                    }}
                                >
                                    2BEa63cba7c9d644de3b9dfa3a991678559
                                </span>
                            </p>
                        </td>
                    </tr>
                    <tr style={{ height: "19.85pt" }}>
                        <td
                            width={669}
                            colSpan={4}
                            style={{
                                width: "501.55pt",
                                border: "none",
                                borderBottom: "dotted windowtext 1.0pt",
                                padding: "0in 5.4pt 0in 5.4pt",
                                height: "19.85pt"
                            }}
                        >
                            <p
                                className="MsoNormal"
                                align="center"
                                style={{
                                    marginTop: "6.0pt",
                                    marginRight: "0in",
                                    marginBottom: "6.0pt",
                                    marginLeft: "0in",
                                    textAlign: "center",
                                    border: "none"
                                }}
                            >
                                <b>
                                    <span
                                        lang="RU"
                                        style={{
                                            fontSize: "11.0pt",
                                            fontFamily: '"Arial",sans-serif',
                                            fontVariant: "small-caps",
                                            color: "black"
                                        }}
                                    >
                                        Обособленное подразделение «Краснодарское»
                                    </span>
                                </b>
                            </p>
                        </td>
                    </tr>
                    <tr style={{ height: "19.85pt" }}>
                        <td
                            width={300}
                            colSpan={2}
                            style={{
                                width: "225.15pt",
                                borderTop: "none",
                                borderLeft: "none",
                                borderBottom: "dotted windowtext 1.0pt",
                                borderRight: "dotted windowtext 1.0pt",
                                padding: "0in 5.4pt 0in 5.4pt",
                                height: "19.85pt"
                            }}
                        >
                            <p
                                className="MsoNormal"
                                style={{
                                    marginTop: "3.0pt",
                                    marginRight: "0in",
                                    marginBottom: "3.0pt",
                                    marginLeft: "0in",
                                    border: "none"
                                }}
                            >
                                <b>
                                    <span
                                        lang="RU"
                                        style={{
                                            fontSize: "11.0pt",
                                            fontFamily: '"Arial",sans-serif',
                                            color: "black"
                                        }}
                                    >
                                        Местонахождение
                                    </span>
                                </b>
                            </p>
                        </td>
                        <td
                            width={369}
                            colSpan={2}
                            style={{
                                width: "276.4pt",
                                border: "none",
                                borderBottom: "dotted windowtext 1.0pt",
                                padding: "0in 5.4pt 0in 5.4pt",
                                height: "19.85pt"
                            }}
                        >
                            <p
                                className="MsoNormal"
                                style={{
                                    marginTop: "3.0pt",
                                    marginRight: "0in",
                                    marginBottom: "3.0pt",
                                    marginLeft: "0in",
                                    border: "none"
                                }}
                            >
                                <span
                                    lang="RU"
                                    style={{
                                        fontSize: "11.0pt",
                                        fontFamily: '"Arial",sans-serif',
                                        color: "black"
                                    }}
                                >
                                    ул. Уральская, д.144, г. Краснодар, 350066
                                </span>
                            </p>
                        </td>
                    </tr>
                    <tr style={{ height: "19.85pt" }}>
                        <td
                            width={300}
                            colSpan={2}
                            style={{
                                width: "225.15pt",
                                borderTop: "none",
                                borderLeft: "none",
                                borderBottom: "dotted windowtext 1.0pt",
                                borderRight: "dotted windowtext 1.0pt",
                                padding: "0in 5.4pt 0in 5.4pt",
                                height: "19.85pt"
                            }}
                        >
                            <p
                                className="MsoNormal"
                                style={{
                                    marginTop: "3.0pt",
                                    marginRight: "0in",
                                    marginBottom: "3.0pt",
                                    marginLeft: "0in",
                                    border: "none"
                                }}
                            >
                                <b>
                                    <span
                                        lang="RU"
                                        style={{
                                            fontSize: "11.0pt",
                                            fontFamily: '"Arial",sans-serif',
                                            color: "black"
                                        }}
                                    >
                                        КПП
                                    </span>
                                </b>
                            </p>
                        </td>
                        <td
                            width={369}
                            colSpan={2}
                            style={{
                                width: "276.4pt",
                                border: "none",
                                borderBottom: "dotted windowtext 1.0pt",
                                padding: "0in 5.4pt 0in 5.4pt",
                                height: "19.85pt"
                            }}
                        >
                            <p
                                className="MsoNormal"
                                style={{
                                    marginTop: "3.0pt",
                                    marginRight: "0in",
                                    marginBottom: "3.0pt",
                                    marginLeft: "0in",
                                    border: "none"
                                }}
                            >
                                <span
                                    lang="RU"
                                    style={{
                                        fontSize: "11.0pt",
                                        fontFamily: '"Arial",sans-serif',
                                        color: "black"
                                    }}
                                >
                                    231245001
                                </span>
                            </p>
                        </td>
                    </tr>
                    <tr style={{ height: "19.85pt" }}>
                        <td
                            width={300}
                            colSpan={2}
                            style={{
                                width: "225.15pt",
                                borderTop: "none",
                                borderLeft: "none",
                                borderBottom: "double windowtext 1.5pt",
                                borderRight: "dotted windowtext 1.0pt",
                                padding: "0in 5.4pt 0in 5.4pt",
                                height: "19.85pt"
                            }}
                        >
                            <p
                                className="MsoNormal"
                                style={{
                                    marginTop: "3.0pt",
                                    marginRight: "0in",
                                    marginBottom: "3.0pt",
                                    marginLeft: "0in",
                                    border: "none"
                                }}
                            >
                                <b>
                                    <span
                                        lang="RU"
                                        style={{
                                            fontSize: "11.0pt",
                                            fontFamily: '"Arial",sans-serif',
                                            color: "black"
                                        }}
                                    >
                                        Адрес офиса и склада
                                    </span>
                                </b>
                            </p>
                        </td>
                        <td
                            width={369}
                            colSpan={2}
                            style={{
                                width: "276.4pt",
                                border: "none",
                                borderBottom: "double windowtext 1.5pt",
                                padding: "0in 5.4pt 0in 5.4pt",
                                height: "19.85pt"
                            }}
                        >
                            <p
                                className="MsoNormal"
                                style={{
                                    marginTop: "3.0pt",
                                    marginRight: "0in",
                                    marginBottom: "3.0pt",
                                    marginLeft: "0in",
                                    border: "none"
                                }}
                            >
                                <span
                                    lang="RU"
                                    style={{
                                        fontSize: "11.0pt",
                                        fontFamily: '"Arial",sans-serif',
                                        color: "black"
                                    }}
                                >
                                    ул. Уральская, д.144, г. Краснодар
                                </span>
                            </p>
                            <p
                                className="MsoNormal"
                                style={{
                                    marginTop: "3.0pt",
                                    marginRight: "0in",
                                    marginBottom: "3.0pt",
                                    marginLeft: "0in",
                                    border: "none"
                                }}
                            >
                                <span
                                    lang="RU"
                                    style={{
                                        fontSize: "11.0pt",
                                        fontFamily: '"Arial",sans-serif',
                                        color: "black"
                                    }}
                                >
                                    офис № 405, склад № 12
                                </span>
                            </p>
                        </td>
                    </tr>
                </tbody>
            </table>
            <p className="MsoNormal" style={{ border: "none" }}>
                <span
                    lang="RU"
                    style={{
                        fontSize: "12.0pt",
                        fontFamily: '"Arial",sans-serif',
                        color: "black"
                    }}
                >
                    &nbsp;
                </span>
            </p>
        </div>
    </>

)