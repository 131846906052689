import { useSearchParams } from 'react-router-dom';
import useStore from '../store/RootStoreContext';

const Search = () => {
    const [searchParams, setSearchParams] = useSearchParams();
    const { userStore } = useStore();
    const {user, manager} = userStore

    const handleSearch = (event) => {
        let value = event.target.value
        if (value.length > 2) {
            setSearchParams({ search: value })
        } else {
            setSearchParams((prev) => delete prev.search)
        }
    }
    return (
        <section className="bg-[#fddb8d] py-4">
            <div className="container mx-auto">
                <div className="flex sm:flex-row sm:px-2 flex-col items-center gap-2 justify-between">
                    <label className="flex relative">
                        <img className="absolute h-4 w-4 left-3 top-3" src="/images/search.svg" alt="" />
                        <input className="bg-[#f8f5f0] outline-0 border-0 h-10 ps-10 w-64 sm:w-96 lg:w-[500px] xl:w-[600px]" type="text" placeholder="поиск по артикулу\наименованию" onChange={(e) => handleSearch(e)} />
                        <button className="bg-[#fbb81b] border-0 cursor-pointer py-2 px-8 hover:shadow-md lg:visible invisible">найти</button>
                    </label>
                    {manager && (
                        <div class="flex gap-4">
                            <img className='sm:visible invisible' src="/images/profile/whatsapp.svg" alt="whatsapp" />
                            <div class="flex flex-col">
                                <p class="search__main-desc">
                                    Ваш&nbsp;менеджер&nbsp; - {manager?.person?.fio}
                                </p>
                                <p class="search__main-desc">
                                    {manager?.person?.phone_number}, {manager?.email}
                                </p>
                            </div>
                        </div>
                    )}

                </div>
            </div>
        </section>
    )
}

export default Search